import { Model } from '@vuex-orm/core'
import DocumentFieldType from '@/models/DocumentFieldType'

export default class DocumentField extends Model {
	static entity = 'documentFields'

	static fields () {
		return {
			id: this.number(0),
			typeId: this.number(0),
			documentId: this.number(0),
			value: this.string(null).nullable(),
			documentFieldType: this.belongsTo(DocumentFieldType, 'typeId', 'id'),
			$valid: this.boolean(false)
		}
	}
}
