<script>
import BaseContainer from 'lowfoot-components/BaseContainer'

export default {
	name: 'Footer',
	components: {
		BaseContainer
	},
	computed: {
		brand: function () {
			return this.$store.getters['session/getBrand']
		}
	}
}
</script>

<template>
	<div class="footer">
		<div class="footer__bar">
			<base-container class="footer__copyright">
				<div class="footer__link" v-if="brand" v-html="brand.footerComponent"></div>
			</base-container>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 0.75rem;
		background-color: var(--footer-bg-colour);
		width: 100%;
		&__link {
			color: var(--footer-text-colour);
			font-size: 0.75rem;
			::v-deep a {
				text-decoration: underline;
				color: var(--link-colour);
				filter: brightness(80%);
			}
		}
		&__bar {
			width: 100%;
			align-items:flex-end;
		}
		&__copyright {
			display: flex;
			justify-content: center;
			padding: 1rem 0;
			text-align: center;
			font-size: 7px;
			color: var(--footer-text-colour);
		}
	}
</style>
