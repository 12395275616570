export default {
	'login': {
		'userAuth': '認証',
		'branchCode': '取扱店コード',
		'branchName': '取扱店',
		'employeeId': '従業員番号',
		'name': '名前',
		'employeeName': '担当',
		'username': '取扱店コード',
		'password': '取扱店名',
		'submit': 'ログイン',
		'logout': 'ログアウト',
		'adminLogin': '管理者ログイン',
		'agentLogin': 'エージェントログイン'
	},
	'dashboard': {
		'dashboard': 'ダッシュボード',
		'inProgress': '手続き中',
		'completed': '完了',
		'newEnrollment': '+ 新規申込',
		'recentEnrollments': '最近の申込み',
		'noEnrollment': '登録なし',
		'submitBy': '担当 '
	},
	'customerField': {
		'label': {
			'name': '申請者名',
			'fullName': '氏名',
			'applicantName': '申請者名',
			'firstName': '名（カタカナ）',
			'lastName': '姓（カタカナ）',
			'phoneNumber': '電話番号',
			'mobilePhoneNumber': '携帯電話番号',
			'contactPhoneNumber': '日中ご連絡のつくお電話番号',
			'alternatePhone': '電話番号（予備）',
			'changeProduct': 'ヒナタメリット',
			'emergencyMaintenance': 'ガス機器かけつけ',
			'birthday': '生年月日',
			'email': 'メールアドレス',
			'notes': '備考',
			'hasGasContract': '現在「大阪ガスの都市ガス」をご契約されていますか。',
			'hasOgContract': '現在「大阪ガスの電気」をご契約されていますか。',
			'hasEneEco': 'エネファームやエコウィルを設置されていますか。',
			'changedPower': '固定価格買取期間中に太陽光発電設備を変更されましたか。',
			'selectProduct': 'ご希望の買取メニューを選択してください。',
			'electricKanaName': '電気検針票のご契約名義（カナ）'
		},
		'option': {
			'yes': 'はい',
			'no': 'いいえ',
			'plan_85': '買取プラン(8.5円/kWh)',
			'plan_90': '電気セットプラン(9.0円/kWh)',
			'plan_95': '電気セット＋スタイルE(9.5円/kWh)',
			'placeholder': '選択してください'
		},
		'error': {
			'name': '名前が必要です',
			'firstName': '名(カタカナ)を入力してください',
			'lastName': '姓(カタカナ)を入力してください',
			'phoneNumber': '電話番号が必要です',
			'alternatePhone': '有効な電話番号を入力して下さい',
			'email': '未入力、または入力内容に誤りがあります。',
			'notes': '',
			'hasGasContract': '「大阪ガスの都市ガス」の契約有無を回答ください',
			'hasOgContract': '「大阪ガスの電気」の契約有無を回答ください',
			'hasEneEco': 'エネファーム等の設置有無を回答ください',
			'changedPower': '太陽光発電設備の変更有無を回答ください',
			'selectProduct': '買取メニューの選択が必要です',
			'electricKanaName': '電気検針票のご契約名義（カナ）を入力してください'
		}
	},
	'enrollment': {
		'customerCardTitle': 'ステップ１<br/>お客さま情報をご入力ください',
		'age_warning': ' 契約者ご本人または満18歳以上のご家族さまがお申込みください ',
		'instructionTitle': '検針票の撮影方法',
		'enrollment': 'お申し込み',
		'New': '新着',
		'in_progress': '手続き中',
		'complete': '完了',
		'invalid': '無効',
		'required': '必須',
		'optional': '任意',
		'repeat': '(確認用)',
		'confirmed': '確認しました',
		'id': '識別番号',
		'date': '日付',
		'dayChar': '日',
		'monthChar': '月',
		'yearChar': '年',
		'gas': 'ガス',
		'ogas': 'ガス',
		'electric': '電気',
		'kfit': '買取期間満了通知',
		'facility': '供給地点',
		'postalCode': '郵便番号',
		'building': '建物',
		'locationDataNotFound': '住所情報が見つかりません。正しい供給地点特定番号を入力してください。',
		'facilityHelperText': '供給地点の情報が表示されない場合は、供給地点特定番号をご確認の上、修正してください。',
		'resetToOriginalValue': '元の値にリセット:',
		'yesReset': 'はい、リセットします',
		'noCancel': 'いいえ、キャンセルします',
		'submitEnrollment': '確認画面へ',
		'confirmationScreen': '確認画面へ',
		'returnDashboard': 'ダッシュボードに戻る',
		'cancelEnrollment': 'キャンセル',
		'customer': 'お客さま情報',
		'applicantName': '申請者名',
		'fullName': '氏名',
		'customerName': 'お客さま情報',
		'phoneNumber': '電話番号',
		'contactPhoneNumber': '日中ご連絡のつくお電話番号',
		'alternatePhone': '電話番号（予備）',
		'notes': '備考',
		'email': 'メールアドレス',
		'address': '住所',
		'billPreference': '課金設定',
		'birthday': '生年月日',
		'paperBill': '紙の請求書',
		'changeProduct': 'ヒナタメリット',
		'emergencyMaintenance': 'ガス機器かけつけ',
		// 'confirm': '確認',
		'titleTermsConditions': '契約条件等の確認をお願いします。',
		'termsConditions': 'テキストの例....',
		'acceptTerms': '私は契約条件等を確認し、承諾します。',
		'submitTerms': 'この検針票を読込む',
		'cancelTerms': 'キャンセルして取り直す',
		'wantToLeave': '本当に去りますか？',
		'cancelEnrollmentTitle': 'キャンセルしますか？',
		'loseInfo': '登録したデータは全て消去されます。',
		'continueToCancel': 'はい、キャンセルします',
		'cancel': 'いいえ、申込みを続けます',
		'submitted': '申込みが完了しました！',
		'continue': '次へ',
		'acceptReturnDashboard': 'はい、ダッシュボードに戻ります',
		'cancelReturnDashboard': 'いいえ、キャンセルして戻る',
		'confirm': {
			'confirmContents': 'お申し込み内容のご確認',
			'yes': '次へ',
			'no': '入力画面に戻る',
			'applicant': 'お申込者',
			'facility': '電気契約のお申込み先',
			'electricityContractType': '現在の電気契約種別',
			'name': '氏名',
			'email': 'メールアドレス',
			'address': '住所',
			'building': '建物名'
		},
		'reminder': {
			'title': 'リマインドメールの設定',
			'subtitle': 'リマインドメールの送信先と送信日時をご入力ください。',
			'cellphoneNumber': '携帯番号',
			'email': 'Eメール',
			'setDateTime': 'リマインド日時',
			'date': '日付',
			'time': '時間',
			'setReminder': 'リマインドメールを設定する',
			'cancel': 'キャンセル',
			'titleSuccess': 'リマインドメールの設定内容',
			'return': '手続画面に戻る',
			'cancelReminder': 'リマイントメールの設定をキャンセルする',
			'titleUnsuccessful': 'ごめんなさい',
			'messageUnsuccessful': 'リマインダーを設定できませんでした。<br/><br/>もう一度やり直してください。',
			'retry': 'リトライ',
			'warning': '選択した日付は過去です。通知がすぐに届きます',
			'error': {
				'alreadySent': '過去24時間以内にリマインドメールが送られています',
				'alreadyScheduled': 'リマインドメールの設定は完了しています'
			}
		},
		'commonMsg': {
			'notApplicable': '適用できません'
		}
	},
	'preview': {
		'yourPhoto': '検針票の写真',
		'submit': '検針票を読込む',
		'cancel': 'キャンセルしてもう一度お試しください'
	},
	'photo': {
		'chooseImage': '画像を選択',
		'noPortrait': '横向きにしてください',
		'helpMessage': 'オレンジ色の枠と検針票の枠を合わせてください',
		'cameraErrorTitle': 'お使いの機種ではカメラを起動できません。',
		'submissionErrorTitle': '画像を処理できません。',
		'returnToEnrollment': '申し込みフォームに戻る',
		'upload': 'アップロード',
		'uploadHelpMessage': '検針票の画像をアップロードしてください。' +
							'アップロードできる画像の形式はJPGもしくはPNGに限ります。',
		'additionalInformation': '追加情報',
		'error': {
			'notReadable': 'ほかのアプリでカメラを使用している可能性があります。カメラの使用有無をご確認の上、やり直してください。<br/><br/>',
			'notAllowed': '以下の手順でカメラの使用を許可してください。' +
				'<br/><br/>1. 申し込みフォームに戻り、改めて「撮影する」ボタンを押してください。' +
				'<br/><br/>2. カメラの使用許可の確認画面が表示されたら、「許可」を選択してください。',
			'overconstrained': 'お使いの端末のカメラでは本アプリをご利用いただけません。申し訳ありませんが電話でお申し込みをお願いします。',
			'overconstrainedPortrait': 'お使いの端末のカメラでは本アプリをご利用いただけません。申し訳ありませんが電話でお申し込みをお願いします。' +
				'<br/><br/>端末を縦向きから横向きに変えてやり直してください。',
			'portrait': '端末を縦向きから横向きに変えてやり直してください。',
			'paused': '以下の手順でカメラの使用を許可してください。' +
				'<br/><br/>1. 申し込みフォームに戻り、改めて「撮影する」ボタンを押してください。' +
				'<br/><br/>2. カメラの使用許可の確認画面が表示されたら、「許可」を選択してください。',
			'technicalIssues': 'カメラが機能しない場合は、画像をアップロードしてください。',
			'submissionError': '申し訳ありませんが、画像の処理中に予期しないエラーが発生しました。 ' +
				'ブラウザの設定に移動し、キャッシュをクリアして、再試行してください。または、別の画像で再試行することもできます。<br/><br/>',
			'invalidDocumentType': '画像が無効です。 アップロードできる画像の形式はJPGもしくはPNGに限ります。<br/><br/>',
			'invalidRequest': '申し訳ありませんが、画像の処理中に予期しないエラーが発生しました。 ' +
				'ブラウザの設定に移動し、キャッシュをクリアして、デバイスを再起動し、再試行してください。<br/><br/>',
			'unexpectedError': '申し訳ありませんが、画像の処理中に予期しないエラーが発生しました。 ' +
				'ブラウザの設定に移動し、キャッシュをクリアして、再試行してください。<br/><br/>'
		}
	},
	'field': {
		'name': 'お客さま名',
		'locationId': '供給地点特定番号',
		'customerId': 'お客さま番号',
		'productName': '契約種別',
		'ampere': '契約容量',
		'totalCost': '使用量',
		'totalUsage': '総使用量',
		'demandCharge': '基本料金',
		'electric_kana_name': '電気検針票のご契約名義（カナ）'
	},
	'documents': {
		'gas': '西部ガスのガス検針票',
		'electric': '九州電力の電気検針票',
		'ogas': 'のガス検針票',
		'kfit': '買取期間満了通知',
		'reScan': '撮り直す',
		'fieldCannotEmpty': 'フィールドは空にできません',
		'saibuold': '古いフォーマットの検針票',
		'saibunew': '新しいフォーマットの検針票',
		'saibu': 'ットの検針票',
		'kyudensmart': 'スマートメーター用の検針票',
		'kyudenanalog': 'アナログメーター用の検針票',
		'kyuden': '撮影する',
		'osaka': '大阪ガス',
		'kepco': '関西電力の買取期間満了通知を読み取ってください',
		'other': 'その他',
		'invalidProduct': '無効な製品',
		'invalidProductInfo': '九州電力の「従量電灯B」「スマートファミリープラン」以外のお客さまは、WEB申込ができません。お手数ですが、お電話にてお申込みください。',
		'callCenterInfo': 'お客さまサービスセンター',
		'callCenterNumber': '0570-000-312',
		'callCenterTime': '受付時間 平日9:00~17:45',
		'error': {
			'badScan': 'データを読み取ることが出来ませんでした。'
		}
	},
	'illustration': {
		'example': '検針票の例を見る',
		'note': '※検針票はイメージです'
	},
	'region': {
		'restart': '再選択する',
		'warning': '電力会社を再選択すると、現在入力中の内容は失われます。',
		'continueButton': '実行する',
		'mapCallout': 'お住まいの地域がどの電力会社エリアか確認したい場合は',
		'selected': {
			'area': '現在 <span style="font-weight: 500">{region}</span> が選択されています。電力会社エリアを再選択しますか？',
			'provider': '現在 <span style="font-weight: 500">{provider}</span> が選択されています。電力会社を再選択しますか？'
		}
	},
	'serviceProvider': {
		'select': {
			'electric': '現在ご利用中の電力会社',
			'gas': '現在使用中のガス会社'
		},
		'auto': 'Auto-selecting provider...',
		'current': 'Your current provider is',
		'other': 'その他（会社名を下記に入力ください）',
		'continue': '次へ'
	},
	'payment': {
		'profile': {
			'title': 'クレジットカード',
			'fieldLabel': {
				'cardNumber': '番号',
				'expDate': '有効期限',
				'month': '月',
				'year': '年',
				'expDateSample': '入力例：04/28',
				'securityCode': 'セキュリティコード',
				'cardHolder': '名義人'
			},
			'message': {
				'cardFailed': 'カードを確認できませんでした',
				'cardSuccessful': '登録カードが確認できました',
				'cardProcessing': 'カードをチェックしています...'
			},
			'error': {
				'cardNumber': '有効なカード番号を入力してください',
				'month': '有効な月を入力してください',
				'year': '有効な年を入力してください',
				'date': '有効な日付を入力してください',
				'securityCode': 'セキュリティコードが必要です',
				'cardHolder': 'カードホルダーが必要です'
			},
			'submit': '登録カードを確認',
			'resetForm': 'カード情報を修正'
		}
	},
	'public': {
		'qr': {
			'saibu': '西部ガスのガス',
			'kyuden': '九州電力の電気',
			'instruction1': '従量電灯Ｂまたはスマートファミリープラン',
			'instruction2': '上記をいずれもご利用の方は',
			'instruction3': '検針票を撮影するだけで<br/>カンタンにお申込みができます !',
			'instruction4': 'スマホで<br/>アクセス'
		},
		'enrollmentAccess': '現在<span style=\'color:red\'>「西部ガスのガスをご契約」かつ「九州電力の従量電灯Bまたはスマートファミリープランをご契約」</span>のお客さまが対象となります。<br/><br/>その他のお客さまは、<br/>お手数ですがお電話にてお申込みください。',
		'applyStart': 'お申し込みへ',
		'gas': 'ステップ２<br/>西部ガスのガス検針票を撮影',
		'electric': 'ステップ３<br/>九州電力の電気検針票を撮影',
		'takePhoto': '撮影する',
		'electricInstruction': '電気検針票には２種類ございます。',
		'electricExample': '検針票の例を見る',
		'or': 'または',
		'reScan': '撮り直す',
		'lostScanPrevious': '撮り直してもよろしいですか？',
		'cancelReScan': '戻る',
		'confirmReScan': '撮り直す',
		'inputError': '未入力、または入力内容に誤りがあります。',
		'returnToForm': '＞入力画面に戻る',
		'titleTerms': 'ご利用規約のご確認',
		'subtitleTerms': '内容に問題がなければ、「同意する」にチェックをして申込へ進んでください。',
		'acceptTerms': '同意する',
		'cancelTerms': '＞確認画面に戻る',
		'submitTerms': '申し込む',
		'applicationCompleted': 'お申込み完了！',
		'thankYou': 'この度は西部ガスの電気にお申込みいただきありがとうございます。<br/>',
		'confirmationMail': 'お手続きが完了しましたら、<br/>「電気の契約手続き完了のお知らせ」を<br/>郵送にてお送りいたします。<br/>',
		'returnLink': '＞西部ガス TOPへ'
	},
	'reload': {
		'title': 'サイトをリロードしますか？',
		'body': 'すべての変更は失われます。',
		'yes': 'はい',
		'cancel': 'キャンセル'
	},
	'commonMsg': {
		'browserNotSupported': 'サポートされていないブラウザです',
		'switchBrowser': {
			'detailed': '残念ながら、このブラウザはこのアプリケーションをサポートしていません。 {browser}に切り替えて続行します。',
			'compact': '。このアプリケーションを使用するには、<strong>{browser}</strong>に切り替えてください。',
			'callout': 'サポートブラウザは、Safari (iOS）と Chrome（Android）です。ご不便をおかけして申し訳ありませんが、サポートされているブラウザでリンクを再度開いてください。'
		},
		'browserAdditionalInstruction': '',
		'browserAdditionalLink': '',
		'nameEmployed': '氏名が必要です',
		'username': 'ユーザー名が必要です',
		'password': 'パスワードが必要',
		'name': '名前が必要です',
		'branchCode': '取扱店コードが必要です',
		'locationId': '撮り直すか、直接ご入力ください',
		'customerId': '撮り直すか、直接ご入力ください',
		'gasLocationId': '',
		'gasCustomerId': '撮り直すか、直接ご入力ください',
		'productName': '必要な製品名',
		'ampere': '必要なアンプ',
		'totalCost': '必要な総費用',
		'totalUsage': '必要な総使用量',
		'fieldOutOfBounds': '255文字以内でお願いします',
		'missingIncompleteField': '未入力、または入力内容に誤りがあります',
		'phoneNumber': '電話番号が必要です',
		'alternatePhone': '有効な電話番号を入力して下さい',
		'demandCharge': 'デマンドチャージが必要です',
		'address': '住所を入力してください',
		'generationOutput': '発電出力を入力してください',
		'facilityId': '設備IDを入力してください',
		'expirationDate': '買取期間満了日を入力してください',
		'youNeedLogin': 'このコンテンツを表示するにはログインする必要があります',
		'incorrectLoginPassword': 'ユーザーネームまたはパスワードが違います',
		'incorrectBranchCode': '不正な取扱店コード',
		'fieldEditedSuccessfully': 'フィールドが正常に編集されました',
		'couldNotUpdateField': 'フィールドを更新できませんでした',
		'couldNotDeleteDocument': 'ドキュメントを削除できませんでした',
		'couldNotGetDocumentResults': 'ドキュメントの結果を取得できませんでした',
		'thereWasErrorCompletingEnrollment': 'この登録の完了中にエラーが発生しました',
		'cantLogout': '現在のユーザーをログアウトできません',
		'couldNotGetEnrollmentDocs': '登録文書を取得できませんでした',
		'fieldResetSuccessfully': 'フィールドが正常にリセットされました',
		'electricLocationIdPatternError': '形式は22桁で、09から始まり0000-0000で終わる必要があります',
		'electricAddressPatternError': '住所の入力は255文字以内でお願いします',
		'gasLocationIdPatternError': '形式は700から始まる10桁である必要があります',
		'electricCustomerIdPatternError': '形式は19桁で、50で始まり31で終わる必要があります',
		'gasCustomerIdPatternError': '形式は900から始まる10桁である必要があります',
		'invalidDate': '有効な日付を入力してください',
		'invalidDateAge': '満18歳未満の方はお申込みいただけません。',
		'patternError': 'パターンエラー',
		'email': '未入力、または入力内容に誤りがあります。',
		'errorPageTitle': 'お探しのページが見つかりません',
		'errorPageInfo': 'お探しのページはURLが変更または削除された可能性がございます。',
		'invalidEmail': '有効なメールアドレスを入力してください',
		'invalidPhone': '有効な電話番号を入力してください',
		'correctFields': '以下の入力内容を訂正して下さい：',
		'thankyou': '有り難う',
		'confirmation': '確認',
		'unexpectedError': '予期しないエラー',
		'duplicateFieldError': '値は一致する必要があります',
		'duplicatePhoneError': '電話番号が一致しません',
		'sessionTimedOut': '長時間ページを開いていたため、セッションタイムアウトが発生しました。',
		'sessionHasExpired': 'お手数ですが、最初から操作をしなおしてください。',
		'restartEnrollment': 'TOPへ戻る',
		'clickHere': 'こちら',
		'existingCustomerDefaultError': 'お客さまはすでに電気をご契約済みです'
	}
}
