import { Model } from '@vuex-orm/core'
import DocumentType from '@/models/DocumentType'

export default class ServiceType extends Model {
	static entity = 'serviceTypes'

	static fields () {
		return {
			id: this.number(0),
			code: this.string(''),
			documentTypes: this.hasMany(DocumentType, 'serviceTypeId', 'id')
		}
	}
}
