import { Model } from '@vuex-orm/core'
import CustomerFieldCategory from '@/models/CustomerFieldCategory'
import CustomerFieldOption from '@/models/CustomerFieldOption'
import CustomerField from '@/models/CustomerField'

export default class CustomerFieldType extends Model {
	static entity = 'customerFieldTypes'

	static fields () {
		return {
			id: this.number(0),
			brandId: this.number(0),
			code: this.string(''),
			errorMessage: this.string(null).nullable(),
			validPattern: this.string(null).nullable(),
			mask: this.string(null).nullable(),
			optional: this.boolean(false),
			enableConfirmation: this.boolean(true),
			enableDuplicate: this.boolean(false),
			customerFieldCategoryId: this.number(0),
			$valid: this.boolean(false),
			customerFieldCategory: this.belongsTo(CustomerFieldCategory, 'customerFieldCategoryId', 'id'),
			customerFieldOptions: this.hasMany(CustomerFieldOption, 'customerFieldTypeId', 'id'),
			customerField: this.hasOne(CustomerField, 'customerFieldTypeId', 'id')
		}
	}
}
