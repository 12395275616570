import EnrollmentService from '@/services/EnrollmentService'
import { mapGetters } from 'vuex'
export default {
	data: () => ({
		enrollmentService: null
	}),
	created: function () {
		this._setReloadHandler()
	},
	activated: function () {
		this._setReloadHandler()
	},
	deactivated: function () {
		if (!this.introCheckpoint) {
			this.$destroy()
		}
	},
	computed: {
		...mapGetters({
			introCheckpoint: 'cms/getIntroCheckpoint',
			reloadCheckpoint: 'cms/getReloadCheckpoint',
			resetCheckpoint: 'cms/getResetCheckpoint'
		}),
		isEnrollmentCreated: function () {
			return this.enrollmentService && this.enrollmentService.enrollmentCreated()
		},
		isCachedOnReload: function () {
			return this.$route.meta.cacheOnReload
		},
		isEnrollmentReset: function () {
			return this.enrollmentService && !this.isEnrollmentCreated
		}
	},
	methods: {
		_setReloadHandler: function () {
			window.onpagehide = async () => this.handlePageHide()
		},
		handlePageHide: async function () {
			if (!this.isCachedOnReload || (this.isEnrollmentReset)) {
				await this.resetCache()
			}
			if (this.isEnrollmentCreated) {
				this.$store.commit('cms/setReloadCheckpoint', true)
			}
		},
		resetCache: async function () {
			this.loading = true
			await EnrollmentService.reset()
			await this.$router.push({ name: 'intro' })
				.catch(() => {})
		},
		restoreCache: async function () {
			this.enrollmentService = await EnrollmentService.restore()
			this.loading = false
		}
	}
}
