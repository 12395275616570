export default {
	flow: {
		introCheckpoint: null,
		confirmationCheckpoint: null,
		preconditionsCheckpoint: null,
		reloadCheckpoint: null,
		resetCheckpoint: null,
		documentsCreated: null,
		documentScanned: null,
		deleteDocumentCheckpoint: null
	},
	device: {
		isDesktop: null,
		isIOSDevice: null,
		isIE: null,
		nativeBrowser: null,
		isUnsupportedBrowser: null,
		isMediaSupported: null,
		videoInputsExist: null,
		isCompatibleDevice: null
	},
	url: {
		queryParameters: []
	}
}
