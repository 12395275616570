<script>
import BaseContainer from 'lowfoot-components/BaseContainer'
import BrandingService from '@/services/BrandingService'

export default {
	name: 'Header',
	components: {
		BaseContainer
	},
	data: () => ({
		brandingConfig: {}
	}),
	computed: {
		brand: function () {
			return this.$store.getters['session/getBrand']
		},
		logoUrl: function () {
			return this.brand.logoUrl
		},
		showTitle: function () {
			return this.brandingConfig && this.brandingConfig.web && this.brandingConfig.web.title !== ''
		},
		isLogoLeftAligned: function () {
			return this.brandingConfig && this.brandingConfig.ui && this.brandingConfig.ui.leftAlignLogo
		},
		centerAlignLogo: function () {
			// if title is displayed, then always center align logo in mobile view
			return this.showTitle || !this.isLogoLeftAligned
		}
	},
	created () {
		this.brandingConfig = BrandingService.get().getConfig()
	}
}
</script>

<template>
	<header class="header" v-if="brand">
		<div class="header__site" v-show="isLogoLeftAligned !== ''">
			<base-container modifiers="default-width responsive" class="header__logo-group"
				:class="{'header__logo-group--centered': centerAlignLogo,
				'header__logo-group--justify' : !(showTitle && centerAlignLogo) }">
				<div class="header__logo-area" :class="{'header__logo-area--centered': centerAlignLogo }">
					<img class="header__logo" v-if="logoUrl !== ''" :src="logoUrl" alt="logo" />
				</div>
				<div class="header__title" v-if="showTitle">{{ brandingConfig.web.title }}</div>
				<div class="header__spacer">&nbsp;</div>
			</base-container>
		</div>
		<div class="header__intro">
			<base-container modifiers="default-width responsive">
				<h1 class="header__text" v-if="brand" v-html="brand.headerMessage"></h1>
			</base-container>
		</div>
	</header>
</template>

<style lang="scss" scoped>
	.header {
		width: 100%;
		background-color: var(--surface-colour);
		color: var(--header-text-colour);
		position: relative;
		margin: 0;
		padding: 0;
		&__site {
			background-color: var(--colour-light);
			min-height: 55px;
			@media (max-width: 550px) {
				padding: 0 0.5rem;
			}
		}
		&__logo-group {
			display: flex;
			align-items: center;
			justify-content: space-evenly;
			min-height: 54px;
			width: 100%;
			padding: 0.5rem 1.5rem;
			@media (max-width: 550px) {
				padding: 0.5rem 0.5rem;
			}

			&--centered {
				@media (max-width: 550px) {
					flex-direction: column;
					align-items: center;
				}
			}

			&--justify {
				justify-content: flex-start;
			}
		}
		&__logo-area {
			box-sizing: border-box;
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			height: 100%;
			width: 33.333%;
			padding-top: 3px;
			&--centered {
				@media (max-width: 550px) {
					justify-content: center;
					width: 100%;
				}
			}
		}
		&__logo {
			max-height: var(--logo-height);
			min-height: 18.65px;
		}
		&__title {
			color:  var(--on-surface);
			font-size: var(--font-size-title);
			font-weight: var(--font-weight-title);
			margin: 0 1rem;
			width: 33.33%;
			text-align: center;
			@media (max-width: 550px) {
				font-size: 1.5rem;
				margin-top: 0.5rem;
				width: 100%;
			}
		}
		&__spacer {
			height: 1px;
			width: 33.33%;
		}
		&__intro {
			width: 100%;
			min-height: var(--intro-height);
			background-color: var(--header-bg-colour);
			display: flex;
			align-items: center;
			padding: 0.84rem 0;
			@media (max-width: 550px) {
				padding: 0.5rem 0.5rem;
			}
		}
		&__text {
			font-size: var(--font-size-headline);
			font-weight: var(--font-weight-headline);
			letter-spacing: var(--letter-spacing-expanded);
			padding: 1rem 1.5rem;
			@media (max-width: 550px) {
				font-size: 1.2rem;
				padding: 1rem 0;
			}
		}
	}
</style>
