import { Database } from '@vuex-orm/core'
import Agreement from '@/models/Agreement'
import Card from '@/models/Card'
import CardContent from '@/models/CardContent'
import ContentItem from '@/models/ContentItem'
import ContentGroup from '@/models/ContentGroup'
import ContentGroupItem from '@/models/ContentGroupItem'
import CustomContent from '@/models/CustomContent'
import ContentDocumentScan from '@/models/ContentDocumentScan'
import CustomerField from '@/models/CustomerField'
import CustomerFieldCategory from '@/models/CustomerFieldCategory'
import CustomerFieldOption from '@/models/CustomerFieldOption'
import CustomerFieldType from '@/models/CustomerFieldType'
import DocumentField from '@/models/DocumentField'
import DocumentFieldCategory from '@/models/DocumentFieldCategory'
import DocumentFieldType from '@/models/DocumentFieldType'
import DocumentIllustration from '@/models/DocumentIllustration'
import DocumentType from '@/models/DocumentType'
import Enrollment from '@/models/Enrollment'
import Facility from '@/models/Facility'
import ProcessingDocument from '@/models/ProcessingDocument'
import ProviderServiceRegion from '@/models/ProviderServiceRegion'
import Region from '@/models/Region'
import ServiceProvider from '@/models/ServiceProvider'
import ServiceType from '@/models/ServiceType'
import QueryParameter from '@/models/QueryParameter'

const database = new Database()

database.register(Agreement)
database.register(Card)
database.register(CardContent)
database.register(ContentItem)
database.register(ContentGroup)
database.register(ContentGroupItem)
database.register(CustomContent)
database.register(ContentDocumentScan)
database.register(CustomerField)
database.register(CustomerFieldCategory)
database.register(CustomerFieldOption)
database.register(CustomerFieldType)
database.register(DocumentField)
database.register(DocumentFieldCategory)
database.register(DocumentFieldType)
database.register(DocumentIllustration)
database.register(DocumentType)
database.register(Enrollment)
database.register(Facility)
database.register(ProcessingDocument)
database.register(ProviderServiceRegion)
database.register(Region)
database.register(ServiceProvider)
database.register(ServiceType)
database.register(QueryParameter)

export default database
