<script>
import BaseModal from 'lowfoot-components/BaseModal'
import reloadPageMixin from '@/mixins/reload-page-mixin.js'
import { mapGetters } from 'vuex'

export default {
	name: 'ReloadModal',
	mixins: [reloadPageMixin],
	components: { BaseModal },
	computed: {
		...mapGetters({ showModal: 'cms/getReloadCheckpoint' })
	},
	mounted () {
		if (this.showModal) this.$modal.show('modalReload')
	},
	methods: {
		reload: async function () {
			await this.resetCache()
			this.$modal.hide('modalReload')
		},
		cancel: async function () {
			await this.restoreCache()
			this.$modal.hide('modalReload')
		}
	},
	watch: {
		showModal: function (newVal) {
			if (newVal) {
				this.$modal.show('modalReload')
			} else {
				this.$modal.hide('modalReload')
			}
		}
	}
}
</script>
<template>
	<div class="reload-modal">
		<base-modal class="reload-modal__main"
					modal-name="modalReload"
					modifiers="padding-1rem"
					:adaptive="true"
					:height="'auto'"
					:clickToClose="false"
					:width="'95%'"
					:max-width="510">
			<p class="reload-modal__title">{{  $t('reload.title') }}</p>
			<p class="reload-modal__body">{{  $t('reload.body') }}</p>
			<div class="reload-modal__actions">
				<base-button class="reload-modal__actions--reload"
							modifiers="accent-inverse small font-small"
							data-testid="button-reload"
							@click="reload">
					{{  $t('reload.yes') }}
				</base-button>
				<base-button class="reload-modal__actions--cancel"
							modifiers="accent small font-small"
							data-testid="button-cancel-reload"
							@click="cancel">
					{{  $t('reload.cancel') }}
				</base-button>
			</div>
		</base-modal>
	</div>
</template>
<style lang="scss" scoped>
.reload-modal {
	&__main {
		text-align: left;
		::v-deep .base-modal {
			@media only screen and (min-width: 500px) {
				justify-content: center;
				padding: 2rem;
			}
		}
	}
	&__title {
		font-size: var(--font-size-subheading);
		font-weight: var(--font-weight-subheading);
		letter-spacing: var(--letter-spacing-expanded);
		margin-bottom: 1rem;
	}
	&__body {
		font-size: var(--font-size-body-1);
		font-weight: var(--font-weight-body-1);
		letter-spacing: var(--letter-spacing-regular);
	}
	&__actions {
		display: flex;
		justify-content: space-between;
		margin-top: 1.5rem;
		@media only screen and (min-width: 500px) {
			justify-content: space-around;
		}
	}
}
</style>
