import { Model } from '@vuex-orm/core'

export default class CustomerField extends Model {
	static entity = 'customerFields'

	static fields () {
		return {
			id: this.uid(),
			customerFieldTypeId: this.number(0),
			enrollmentId: this.number(0),
			value: this.string(null).nullable(),
			$isNew: this.boolean(false),
			$isDirty: this.boolean(false)
		}
	}
}
