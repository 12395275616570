import { Model } from '@vuex-orm/core'
import CardContent from '@/models/CardContent'

export default class Card extends Model {
	static entity = 'cards'

	static fields () {
		return {
			id: this.number(0),
			name: this.string(''),
			order: this.number(0),
			$valid: this.boolean(false),
			cardContents: this.hasMany(CardContent, 'cardId', 'id')
		}
	}
}
