import { Model } from '@vuex-orm/core'
import ContentItem from '@/models/ContentItem'

export default class ContentGroupItem extends Model {
	static entity = 'contentGroupItems'

	static primaryKey = ['contentGroupId', 'contentItemId']

	static fields () {
		return {
			contentGroupId: this.number(0),
			contentItemId: this.number(0),
			order: this.number(0),
			contentItem: this.hasOne(ContentItem, 'id', 'contentItemId')
		}
	}
}
