<script>
import BaseContainer from 'lowfoot-components/BaseContainer'
import Header from '@/components/shared/Header'
import Footer from '@/components/shared/Footer'
import ReloadModal from '@/components/shared/ReloadModal'

export default {
	name: 'PublicLayout',
	components: { BaseContainer, Header, Footer, ReloadModal }
}
</script>
<template>
	<div class="public-layout" >
		<Header />
			<base-container class="public-layout__container"
							modifiers="default-width column-layout responsive">
				<slot />
			</base-container>
		<Footer />
		<reload-modal />
	</div>
</template>
<style lang="scss" scoped>
.public-layout {
	background-color: var(--background-colour);
	color: var(--on-background);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	&__container {
		animation-name: fade;
		animation-duration: 1s;
		padding-top: 1rem;
		padding-bottom: 1rem;
		min-height: 70vh;
		display: flex;
		flex-direction: column;
	}
	@keyframes fade {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}
</style>
