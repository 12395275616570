import ProcessingDocument from '@/models/ProcessingDocument'

export default class ProcessingDocumentRepo {
	static endpoint = '/api/enrollment-document'

	static delete (documentId: number) {
		return ProcessingDocument.api().delete(`${this.endpoint}?document-id=${documentId}`, {
			delete: documentId
		})
	}
	static async post (document) {
		return ProcessingDocument.api().post('/api/processing-document', document)
	}
}
