import Enrollment from '@/models/Enrollment'
import EnrollmentRepo from '@/repos/EnrollmentRepo'
import CustomerFieldRepo from '@/repos/CustomerFieldRepo'
import CardService from '@/services/CardService'
import { InstanceOf } from '@vuex-orm/core'
import Card from '@/models/Card'
import DocumentService from '@/services/DocumentService'
import ServiceTypeService from '@/services/ServiceTypeService'
import QueryParameterService from '@/services/QueryParameterService'
import ContentDocumentScan from '@/models/ContentDocumentScan'
import store from '../store'

export default class EnrollmentService {
	private static instance?: EnrollmentService
	private static createEnrollment?: Promise<void>
	private enrollment: InstanceOf<Enrollment>
	private hasElectricGenericProviderFlag?: Boolean

	private constructor (enrollment: InstanceOf<Enrollment>) {
		this.enrollment = enrollment
	}

	static async get () {
		let enrollment: InstanceOf<Enrollment>
		if (!EnrollmentService.instance) {
			// create a placeholder Enrollment with id=0 till it gets saved to db
			enrollment = await Enrollment.new()
			EnrollmentService.instance = new EnrollmentService(enrollment)
		}
		// make sure most recent enrollment model is being used
		enrollment = EnrollmentService.instance.getCachedEnrollment()
		EnrollmentService.instance.setEnrollment(enrollment)
		return EnrollmentService.instance
	}

	getCachedEnrollment () {
		const CACHED = 1
		const DEFAULT = 0
		return Enrollment.all()[CACHED] || Enrollment.all()[DEFAULT]
	}

	static async fetchOrCreate () {
		await EnrollmentService.getCreateEnrollmentPromise()
		const enrollmentService = await EnrollmentService.get()
		await this.populateProcessingDocuments(enrollmentService)
		await QueryParameterService.storeQueryParameters(enrollmentService.getId())
		return enrollmentService
	}

	static async populateProcessingDocuments (enrollmentService) {
		const contentDocumentScans = enrollmentService.getContentDocumentScans()
		if (store.getters['cms/getDocumentsCreated']) return
		for (const contentDocumentScan of contentDocumentScans) {
			store.commit('cms/setDocumentsCreated', true)
			const documentService = await new DocumentService(enrollmentService, contentDocumentScan)
			const document = documentService.getProcessingDocument()
			if (!document || document.status === 'REJECTED' || (document.status !== 'NEW' && contentDocumentScan.id &&
				store.getters['cms/getDocumentScanned'] === contentDocumentScan.id)) {
				await documentService.createDocument()
			}
		}
	}

	static getCreateEnrollmentPromise () {
		if (!EnrollmentService.createEnrollment) {
			// replace id=0 Enrollment placeholder with the one from the db
			EnrollmentService.createEnrollment = new Promise((resolve) =>
				resolve(Enrollment.find(0))
			).then(async function (enrollment: InstanceOf<Enrollment>) {
				if (!enrollment) return
				const createdEnrollment = await EnrollmentRepo.create(enrollment)
				await Enrollment.update({
					id: createdEnrollment.id,
					$valid: enrollment.$valid,
					$regionId: enrollment.$regionId,
					$serviceProviderId: enrollment.$serviceProviderId,
					$serviceProviderName: enrollment.$serviceProviderName
				})
				await Enrollment.delete(0)
				await CustomerFieldRepo.fetchByEnrollmentId(createdEnrollment.id)
			})
		}
		return EnrollmentService.createEnrollment
	}

	static async reset () {
		window.onpagehide = null
		await store.dispatch('entities/deleteAll')
		store.commit('cms/resetFlow')
		store.commit('cms/resetUrl')
		store.commit('cms/setResetCheckpoint', true)
		EnrollmentService.instance = undefined
	}

	static async restore () {
		store.commit('cms/setReloadCheckpoint', false)
		store.commit('cms/setPreconditionsCheckpoint', true)
		await Enrollment.delete(0)
		const enrollmentService = await EnrollmentService.get()
		await CustomerFieldRepo.fetchByEnrollmentId(enrollmentService.getId())
		return enrollmentService
	}

	async updateValidity (valid: boolean) {
		this.getEnrollment().$valid = valid
		await Enrollment.update({ id: this.getEnrollment().id, $valid: valid })
	}

	isComplete () {
		return this.getStatus() === 'COMPLETE'
	}

	async completeEnrollment () {
		this.getEnrollment().status = 'COMPLETE'
		await EnrollmentRepo.update(this.getEnrollment())
		await QueryParameterService.reset()
	}

	async validate () {
		const valid = CardService.validateAll()
		await this.updateValidity(valid)
		return valid
	}

	isValid () {
		return this.getEnrollment().$valid
	}

	async setRegionId (regionId: number) {
		this.getEnrollment().$regionId = regionId
		await Enrollment.update({ id: this.getEnrollment().id, $regionId: regionId })
	}

	getRegionId () {
		return this.getEnrollment().$regionId
	}

	getEnrollment () {
		return this.enrollment
	}

	setEnrollment (enrollment: InstanceOf<Enrollment>) {
		this.enrollment = enrollment
	}

	async setFacilityId (facilityId: any) {
		this.getEnrollment().facilityId = facilityId
		await Enrollment.update({ id: this.getEnrollment().id, facilityId: facilityId })
	}

	getFacilityId () {
		return this.getEnrollment().facilityId
	}

	enrollmentCreated () {
		return this.getId() > 0
	}

	getId () {
		return this.getEnrollment().id
	}

	getStatus () {
		return this.getEnrollment().status
	}

	isIncumbentOnly () {
		return (this.getContentDocumentScans()
			.filter(contentDocumentScan => contentDocumentScan && contentDocumentScan.scope !== 'INCUMBENT').length < 1)
	}

	getContentDocumentScans () {
		let current: any = []
		const cards: any = Card.query().get()
		for (const card of cards) {
			const contentDocumentScans = DocumentService.getAllContentDocumentScansByCard(card.id)
			current = [...current, ...contentDocumentScans]
		}
		return current
	}

	hasElectricGenericProvider () {
		if (!this.hasElectricGenericProviderFlag) {
			const serviceType: any = ServiceTypeService.findByCode('ELECTRIC')
			this.hasElectricGenericProviderFlag = ContentDocumentScan.query()
				.where('serviceTypeId', serviceType.id)
				.where('$serviceProviderId', null)
				.exists()
		}
		return this.hasElectricGenericProviderFlag
	}

	async submitSignature (signatureImage) {
		const enrollmentId = this.getId()
		const signatureResponse = await fetch(signatureImage)
		const signatureBlob = await signatureResponse.blob()
		await EnrollmentRepo.submitSignature(enrollmentId, signatureBlob)
	}
}
