import Facility from '@/models/Facility'
import FacilityRepo from '@/repos/FacilityRepo.ts'
import { InstanceOf } from '@vuex-orm/core'

export default class FacilityService {
	static async reset () {
		await Facility.deleteAll()
	}

	static async loadFacility (enrollmentService, locationId) {
		try {
			const enrollmentId = enrollmentService.getId()
			const regionId = enrollmentService.getRegionId()
			const apiFacility: InstanceOf<Facility> = await FacilityRepo.create(enrollmentId, locationId, regionId)
			if (apiFacility) await enrollmentService.setFacilityId(apiFacility.id)
			return apiFacility
		} catch (error) {
			await enrollmentService.setFacilityId(null)
			throw error
		}
	}

	static getFacility (enrollmentService) {
		return Facility.find(enrollmentService.getFacilityId())
	}

	isExistingCustomer () {
		const facility: InstanceOf<Facility> = Facility.query().last()
		return (facility.isExistingCustomer != null && facility.isExistingCustomer)
	}
}
