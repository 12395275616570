import { Model } from '@vuex-orm/core'
import CustomerFieldType from '@/models/CustomerFieldType'

export default class CustomerFieldOption extends Model {
	static entity = 'customerFieldOptions'

	static fields () {
		return {
			id: this.number(0),
			customerFieldTypeId: this.number(0),
			key: this.string(''),
			value: this.string(null).nullable(),
			defaultOption: this.boolean(false),
			label: this.string(null).nullable(),
			customerFieldType: this.belongsTo(CustomerFieldType, 'customerFieldTypeId', 'id'),
			$valid: this.boolean(false)
		}
	}
}
