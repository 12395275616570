import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import router from './router'
import Vue from 'vue'

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		Vue,
		dsn: 'https://6aeafb121181476e91589969abc36b44@o955145.ingest.sentry.io/6065292',
		release: process.env.npm_package_version,
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ['localhost', /^\//]
			})
		],
		tracesSampleRate: 1.0
	})
}
