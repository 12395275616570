import { Model } from '@vuex-orm/core'

export default class Facility extends Model {
	static entity = 'facilities'

	static fields () {
		return {
			id: this.number(0),
			found: this.boolean(false),
			apiAddress: this.string(''),
			apiBuilding: this.string(''),
			apiPostalCode: this.string(''),
			isExistingCustomer: this.boolean(false)
		}
	}
}
