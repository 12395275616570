import { Model } from '@vuex-orm/core'

export default class ContentDocumentScan extends Model {
	static entity = 'contentDocumentScan'

	static fields () {
		return {
			id: this.number(0),
			serviceTypeId: this.number(0),
			optional: this.boolean(false),
			scope: this.string('INCUMBENT'),
			$serviceProviderId: this.number(null).nullable(),
			$serviceProviderName: this.string(''),
			$valid: this.boolean(undefined)
		}
	}
}
