import axios from 'axios'

export function axiosInterceptor (store, router) {
	axios.interceptors.response.use(response => {
		return response
	}, error => {
		if (authError(error)) {
			router.push({ name: 'error', params: { sessionTimeOut: true } })
		}
		throw error
	})
}

function authError (error) {
	if (error && error.response) {
		return error.response.status === 403 || error.response.status === 401
	}
}
