import cmsDefaults from '@/store/cms/defaults'

const url = {
	state: {
		url: { ...cmsDefaults.url }
	},
	getters: {
		getQueryParameters: function (state) {
			return state.url.queryParameters
		}
	},
	mutations: {
		resetUrl: function (state) {
			state.url = { ...cmsDefaults.url }
		},
		setQueryParameters: function (state, queryParameters) {
			state.url.queryParameters = queryParameters
		}
	}
}

export const urlState = url.state
export const urlGetters = url.getters
export const urlMutations = url.mutations
export const urlActions = url.actions
