import ContentGroup from '@/models/ContentGroup'
import CardContent from '@/models/CardContent'
import ContentDocumentScan from '@/models/ContentDocumentScan'
import ContentItem from '@/models/ContentItem'
import ContentGroupItem from '@/models/ContentGroupItem'
import CustomerFieldService from '@/services/CustomerFieldService'
import CardService from '@/services/CardService'
import EnrollmentService from '@/services/EnrollmentService'
import DocumentService from '@/services/DocumentService'
import { InstanceOf } from '@vuex-orm/core'

export default class ContentGroupService {
	private contentGroupId: number
	private enrollmentService:EnrollmentService
	private contentDocumentScans: Array<InstanceOf<ContentDocumentScan>>
	private cardService?: CardService
	private toggleContentItem?: InstanceOf<ContentItem>
	private contentGroupItems?: Array<ContentGroupItem>

	constructor (enrollmentService: EnrollmentService, contentGroupId: number) {
		this.contentGroupId = contentGroupId
		this.enrollmentService = enrollmentService
		this.contentDocumentScans = DocumentService.getContentDocumentScansByContentGroup(contentGroupId)
	}

	static validateAllByCardId (cardId: number) {
		const allValidStates:Array<boolean> = ContentGroupService.getAllByCardId(cardId)
			.map((contentGroup: InstanceOf<ContentGroup>) => !contentGroup.$show || contentGroup.$valid)
		return allValidStates.every(contentGroupValid => contentGroupValid)
	}

	static getAllByCardId (cardId: number) {
		return CardContent.query().where('cardId', cardId).orderBy('order').withAll().get()
			.filter((cardContent: InstanceOf<CardContent>) => cardContent.contentItem &&
				cardContent.contentItem.contentType === 'CONTENT_GROUP')
			.map((cardContent: InstanceOf<CardContent>) =>
				ContentGroup.find(cardContent.contentItem.contentGroupId))
	}

	static findByCustomerFieldTypeId (enrollmentService: EnrollmentService, customerFieldTypeId: number) {
		const contentGroupItem: InstanceOf<ContentGroupItem> =
			ContentGroupItem.query().whereHas('contentItem', (query) => {
				query.where('customerFieldTypeId', customerFieldTypeId)
			}).withAll().first()
		return contentGroupItem ? new ContentGroupService(enrollmentService, contentGroupItem.contentGroupId) : undefined
	}

	async validate () {
		const visibility = await this.updateVisibility()
		const valid = visibility ? this.validateContent() : true
		await this.updateValidity(valid)
		return valid
	}

	validateContent () {
		return CustomerFieldService.validateAllByContentGroup(this.contentGroupId) &&
			DocumentService.validateAllByContentGroup(this.contentGroupId)
	}

	async updateValidity (valid: boolean) {
		const contentGroup: InstanceOf<ContentGroup> = this.getContentGroup()
		await ContentGroup.update({ id: contentGroup.id, $valid: valid })
		await this.updateParentValidity()
	}

	async updateParentValidity () {
		// recalculate validation of the card this field belongs to
		await this.getCardService().validate()
	}

	getCardService () {
		if (!this.cardService) {
			this.cardService = new CardService(this.enrollmentService, this.getCardId())
		}
		return this.cardService
	}

	async setShow (show: boolean) {
		const contentGroup: InstanceOf<ContentGroup> = this.getContentGroup()
		await ContentGroup.update({ id: contentGroup.id, $show: show })
	}

	isVisible () {
		const contentGroup: InstanceOf<ContentGroup> = this.getContentGroup()
		return contentGroup && contentGroup.$show
	}

	getContentItem () {
		return ContentItem.query().where('contentGroupId', this.contentGroupId).first()
	}

	getCardContent () {
		let contentItem: InstanceOf<ContentItem> = this.getContentItem()
		return CardContent.query().where(cardContent => cardContent.contentItemId === contentItem.id).first()
	}

	getCardId () {
		const cardContent: InstanceOf<CardContent> = this.getCardContent()
		return cardContent ? cardContent.cardId : null
	}

	getValid () {
		const contentGroup: InstanceOf<ContentGroup> = this.getContentGroup()
		return contentGroup && contentGroup.$valid
	}

	getToggleContentItem () {
		if (!this.toggleContentItem) {
			const contentGroup: InstanceOf<ContentGroup> = this.getContentGroup()
			this.toggleContentItem = ContentItem.find(contentGroup.toggleContentItemId)
		}
		return this.toggleContentItem
	}

	getContentGroup () {
		return ContentGroup.find(this.contentGroupId)
	}

	getContentGroupItems (contentType?:String) {
		if (!this.contentGroupItems) {
			this.contentGroupItems = ContentGroupItem.query().where('contentGroupId', this.contentGroupId)
				.orderBy('order').withAllRecursive().get()
		}
		return this.contentGroupItems.filter((contentGroupItem: InstanceOf<ContentGroupItem>) =>
			contentType == null || contentGroupItem.contentItem.contentType === contentType)
	}

	async updateVisibility () {
		const visibility = this.checkVisibility()
		await this.setShow(visibility)
		return visibility
	}

	checkVisibility () {
		const contentGroup: InstanceOf<ContentGroup> = this.getContentGroup()
		const togglePattern = new RegExp(contentGroup.togglePattern)
		const toggleContentItem: InstanceOf<ContentItem> = this.getToggleContentItem()
		if (toggleContentItem && toggleContentItem.contentType === 'CUSTOMER_FIELD') {
			const customerFieldService = new CustomerFieldService(this.enrollmentService, toggleContentItem.customerFieldTypeId)
			return togglePattern.test(customerFieldService.getValue())
		}
		return false
	}
}
