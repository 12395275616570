/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import session from './session'
import cms from './cms/cms'

// ORM
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import database from '@/database'

// Persisted State
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
VuexORM.use(VuexORMAxios, { axios })

export default new Vuex.Store({
	plugins: [VuexORM.install(database), createPersistedState({
		paths: ['cms', 'entities'],
		storage: window.sessionStorage
	})],
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		session,
		cms
	}
})
