import { Model } from '@vuex-orm/core'

export default class QueryParameter extends Model {
	static entity = 'queryParameters'

	static fields () {
		return {
			id: this.number(0),
			enrollmentId: this.number(0),
			key: this.string(''),
			value: this.string('')
		}
	}
}
