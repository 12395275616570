import { Model } from '@vuex-orm/core'
import ServiceProvider from '@/models/ServiceProvider'

export default class ProviderServiceRegion extends Model {
	static entity = 'providerServiceRegions'
	static primaryKey = ['serviceProviderId', 'regionId', 'serviceTypeId']
	static fields () {
		return {
			serviceProviderId: this.number(0),
			serviceTypeId: this.number(0),
			regionId: this.number(0),
			isIncumbent: this.boolean(false),
			serviceProvider: this.belongsTo(ServiceProvider, 'serviceProviderId', 'id')
		}
	}
}
