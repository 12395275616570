import { Model } from '@vuex-orm/core'

export default class Enrollment extends Model {
	static entity = 'enrollments'

	static fields () {
		return {
			id: this.number(0),
			brandId: this.number(0),
			facilityId: this.number(null).nullable(),
			storeId: this.number(0),
			employeeName: this.string('CMS'),
			status: this.string('NEW'),
			created: this.string(''), // convert to moment?
			$regionId: this.number(null).nullable(),
			$valid: this.boolean(false)
		}
	}
}
