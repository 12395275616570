import { deviceActions, deviceGetters, deviceMutations, deviceState } from './device'
import { flowActions, flowGetters, flowMutations, flowState } from './flow'
import { urlActions, urlGetters, urlMutations, urlState } from './url'

const cms = {
	namespaced: true,
	state: {
		...deviceState,
		...flowState,
		...urlState
	},
	getters: {
		...deviceGetters,
		...flowGetters,
		...urlGetters
	},
	mutations: {
		...deviceMutations,
		...flowMutations,
		...urlMutations
	},
	actions: {
		...deviceActions,
		...flowActions,
		...urlActions
	}
}

export default cms
