import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import i18n from './i18n'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons/faFileUpload'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo'
import { faWallet } from '@fortawesome/free-solid-svg-icons/faWallet'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare'
import { faList } from '@fortawesome/free-solid-svg-icons/faList'
import { faGripVertical } from '@fortawesome/free-solid-svg-icons/faGripVertical'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faICursor } from '@fortawesome/free-solid-svg-icons/faICursor'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons/faFilePdf'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons/faTrashAlt'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import PublicLayout from '@/layouts/PublicLayout.vue'

import BaseLoading from 'lowfoot-components/BaseLoading.vue'
import BaseButton from 'lowfoot-components/BaseButton.vue'

import { Scroll } from 'lowfoot-components/directives/scroll'
import store from './store'
import VModal from 'vue-js-modal'
import { axiosInterceptor } from '@/middleware/axios-interceptor.ts'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import ReactiveProvide from 'vue-reactive-provide'
import './sentry'

axios.defaults.baseURL = '/ocr/'
axiosInterceptor(store, router)
library.add(faCamera, faFileUpload, faCheck, faTimes, faChevronRight, faChevronDown, faSearchPlus, faUndo, faWallet,
	faSpinner, faSquare, faCheckSquare, faSyncAlt, faExclamationCircle, faMobileAlt, faClock, faCreditCard,
	faExternalLinkAlt, faList, faGripVertical, faPlusCircle, faICursor, faFilePdf, faTrashAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('public-layout', PublicLayout)
Vue.component('base-button', BaseButton)
Vue.component('base-loading', BaseLoading)

Vue.directive('scroll', Scroll)

Vue.config.productionTip = false

const moment = require('moment')
require('moment/locale/ja')

Vue.use(require('vue-moment'), {
	moment
})
Vue.use(VModal)

Vue.use(Vuelidate)

Vue.use(ReactiveProvide)

new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount('#app')
