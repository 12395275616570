import Vue from 'vue'
import VueRouter from 'vue-router'
import cmsRoutes from '@/router/cms.ts'

Vue.use(VueRouter)

const routes = [ ...cmsRoutes, { path: '*', redirect: '/' } ]

const scrollBehavior = function (to, from, savedPosition) {
	if (savedPosition) {
		// savedPosition is only available for popstate navigations.
		return savedPosition
	} else {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				let position = {
					selector: null,
					offset: {},
					behavior: 'smooth'
				}
				// scroll to anchor by returning the selector
				if (to.hash) {
					position.selector = to.hash
					// specify offset of the element
					if (/^#card/.test(to.hash)) {
						position.offset = { y: 20 }
					}
					if (/^#content-document-scan/.test(to.hash)) {
						position.offset = { y: 10 }
					}
					// bypass #number check
					if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
						resolve(position)
					}
				}
				// if there is no specified anchor, scroll to top
				resolve({ x: 0, y: 0 })
			}, 0)
		})
	}
}

const router = new VueRouter({
	mode: 'history',
	base: '/ocr/cms',
	scrollBehavior,
	routes
})

export default router
