import axios from 'axios'
import Enrollment from '@/models/Enrollment'
import { InstanceOf } from '@vuex-orm/core'

export default class EnrollmentRepo {
	static endpoint = '/api/enrollment'

	static fetchById (enrollmentId: number) {
		return Enrollment.api().get(`${this.endpoint}?enrollment-id=${enrollmentId}`)
	}

	static async create (enrollment: InstanceOf<Enrollment>) {
		const result = await Enrollment.api().post(this.endpoint, { 'employeeName': enrollment.employeeName, 'storeId': enrollment.storeId })
		const createdEnrollment: InstanceOf<Enrollment> = result.entities ? result.entities.enrollments[0] : null
		enrollment.id = createdEnrollment ? createdEnrollment.id : enrollment.id
		return createdEnrollment
	}

	static update (enrollment: InstanceOf<Enrollment>) {
		return Enrollment.api().put(this.endpoint, enrollment)
	}

	static async submitSignature (enrollmentId: number, signatureImage: any) {
		const request = this._prepareSignatureRequest(enrollmentId, signatureImage)
		const response = await axios.post('/api/electronic-signature', request.data, request.config)
		return response.data
	}

	static _prepareSignatureRequest (enrollmentId, image) {
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }
		const data = new FormData()
		data.append('enrollment-id', enrollmentId)
		data.append('image', image, image.name)
		return { data, config }
	}

	static rejectDocuments (enrollmentId: number, serviceTypeId: number) {
		return axios.put('/api/processing-document', { enrollmentId, serviceTypeId })
	}
}
