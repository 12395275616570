export default {
	'login': {
		'userAuth': 'User authentication',
		'branchCode': 'Agency Branch Code',
		'branchName': 'Branch Name',
		'employeeId': 'Employee ID',
		'name': 'Name',
		'employeeName': 'Employee Name',
		'username': 'Username',
		'password': 'Password',
		'submit': 'Submit',
		'logout': 'Logout',
		'adminLogin': 'Admin Login',
		'agentLogin': 'Agent Login'
	},
	'dashboard': {
		'dashboard': 'Dashboard',
		'inProgress': 'In Progress',
		'completed': 'Completed',
		'newEnrollment': '+ New Enrollment',
		'recentEnrollments': 'Recent Enrollments',
		'noEnrollment': 'No Enrollment',
		'submitBy': 'Submit by '
	},
	'customerField': {
		'label': {
			'name': 'Applicant Name',
			'fullName': 'Full Name',
			'applicantName': 'Applicant Name',
			'firstName': 'First Name',
			'lastName': 'Last Name',
			'phoneNumber': 'Phone Number',
			'mobilePhoneNumber': 'Mobile Phone Number',
			'contactPhoneNumber': 'Phone Number',
			'alternatePhone': 'Alternate Phone Number',
			'changeProduct': 'Change gas product',
			'emergencyMaintenance': 'Emergency maintenance required',
			'birthday': 'Birthday',
			'email': 'Email Address',
			'notes': 'Additional Notes',
			'hasGasContract': 'Do you currently have a gas contract for the City of Osaka?',
			'hasOgContract': 'Do you currently have an Osaka Gas Electricity contract?',
			'hasEneEco': 'Do you have ENE-FARM or ECO WILL installed?',
			'changedPower': 'Did you change the photovoltaic power generation facility during the fixed price purchase period?',
			'selectProduct': 'Please select your product',
			'electricKanaName': 'Contract Name (Kana)'
		},
		'option': {
			'yes': 'Yes',
			'no': 'No',
			'plan_85': 'Purchase plan (8.5 yen / kWh)',
			'plan_90': 'Electric set plan (9.0 yen / kWh)',
			'plan_95': 'Electric set + style E (9.5 yen / kWh)',
			'placeholder': 'Please select an option'
		},
		'error': {
			'name': 'Name is required',
			'firstName': 'First Name is required',
			'lastName': 'Last Name is required',
			'phoneNumber': 'Phone Number is required',
			'alternatePhone': 'Please enter a valid phone number',
			'email': 'Email address is required',
			'notes': '',
			'hasGasContract': 'hasGasContract is required',
			'hasOgContract': 'hasOgContract is required',
			'hasEneEco': 'hasEneEco is required',
			'changedPower': 'changedPower is required',
			'selectProduct': 'selectProduct is required',
			'electricKanaName': 'Contract Name is required'
		}
	},
	'enrollment': {
		'customerCardTitle': 'Step1. <br> Enter customer information',
		'age_warning': 'Please apply for yourself or a family member who is 18 years old or older',
		'instructionTitle': 'How to take a meter reading',
		'enrollment': 'Enrollment',
		'new': 'New',
		'in_progress': 'In Progress',
		'complete': 'Completed',
		'invalid': 'Invalid',
		'required': 'Required',
		'optional': 'Optional',
		'repeat': '(repeat)',
		'confirmed': 'Confirmed',
		'id': 'ID',
		'date': 'Date',
		'dayChar': 'D',
		'monthChar': 'M',
		'yearChar': 'Y',
		'gas': 'Gas',
		'ogas': 'Gas',
		'electric': 'Electric Power',
		'kfit': 'FIT Notice',
		'facility': 'Facility',
		'postalCode': 'Postal Code',
		'building': 'Building',
		'locationDataNotFound': 'Location data not found. Please enter the correct Location Identification number.',
		'facilityHelperText': 'If the supply point information is not displayed, check the supply point identification number and correct it.',
		'resetToOriginalValue': 'Reset to original value:',
		'yesReset': 'Yes, reset',
		'noCancel': 'No, cancel',
		'submitEnrollment': 'Submit Enrollment',
		'confirmationScreen': 'Confirmation screen',
		'returnDashboard': 'Return to Dashboard',
		'cancelEnrollment': 'Cancel Enrollment',
		'customer': 'Customer',
		'applicantName': 'Applicant Name',
		'fullName': 'Full Name',
		'customerName': 'Customer',
		'phoneNumber': 'Phone Number',
		'contactPhoneNumber': 'Phone Number',
		'alternatePhone': 'Alternate Phone Number',
		'notes': 'Additional Notes',
		'email': 'Email Address',
		'address': 'Address',
		'birthday': 'Birthday',
		'billPreference': 'Bill Preference',
		'paperBill': 'Paper Bill',
		'changeProduct': 'Change gas product',
		'emergencyMaintenance': 'Emergency maintenance required',
		// 'confirm': 'Confirm',
		'titleTermsConditions': 'Please accept the terms and conditions:',
		'termsConditions': 'Lorem ipsum....',
		'acceptTerms': 'I accept the terms above',
		'submitTerms': 'Yes, continue submission',
		'cancelTerms': 'No, cancel and go back',
		'wantToLeave': 'Are you sure you want to leave?',
		'cancelEnrollmentTitle': 'Cancel enrollment?',
		'loseInfo': 'All data will be lost.',
		'continueToCancel': 'Yes, cancel enrollment',
		'cancel': 'No, return to enrollment',
		'submitted': 'Enrollment submitted successfully!',
		'continue': 'Continue',
		'acceptReturnDashboard': 'Yes, return to dashboard',
		'cancelReturnDashboard': 'No, cancel and go back',
		'confirm': {
			'confirmContents': 'Confirmation of application contents',
			'yes': 'Next',
			'no': 'Return to the input screen',
			'applicant': 'Applicant',
			'facility': 'Electric Information',
			'electricityContractType': 'Electric Contract',
			'name': 'Name',
			'email': 'Email',
			'address': 'Address',
			'building': 'Building'
		},
		'reminder': {
			'title': 'Set a Reminder',
			'subtitle': 'Please enter the recipient, date and time of the reminder message.',
			'cellphoneNumber': 'Mobile Number',
			'email': 'Email',
			'setDateTime': 'Reminder Date and Time',
			'date': 'Date',
			'time': 'Time',
			'setReminder': 'Set reminder',
			'cancel': 'Cancel',
			'titleSuccess': 'Reminder Set',
			'return': 'Return to previous screen',
			'cancelReminder': 'Cancel the reminder',
			'titleUnsuccessful': 'Sorry',
			'messageUnsuccessful': 'The reminder could not be set<br/><br/>Please try again.',
			'retry': 'Retry',
			'warning': 'Selected date is in the past, reminder will arrive immediately',
			'error': {
				'alreadySent': 'A reminder was already sent to {recipient} within 24 hours',
				'alreadyScheduled': 'Reminder already scheduled for {recipient}'
			}
		},
		'commonMsg': {
			'notApplicable': 'Not applicable'
		}
	},
	'preview': {
		'yourPhoto': 'Your Photo',
		'submit': 'Submit',
		'cancel': 'Cancel and try again'
	},
	'photo': {
		'chooseImage': 'Choose Image',
		'noPortrait': 'Please switch to landscape mode',
		'helpMessage': 'Align the orange frame with the bill frame',
		'cameraErrorTitle': 'Unable to start camera on this device.',
		'submissionErrorTitle': 'Unable to process image.',
		'returnToEnrollment': 'Return to the Enrollment Form',
		'upload': 'Upload',
		'uploadHelpMessage': 'Please upload an image of your meter reading slip. ' +
							'The image format must be PNG or JPG.',
		'additionalInformation': 'Additional Information',
		'error': {
			'notReadable': 'Another application might be using the camera. Please check and try again.',
			'notAllowed': 'Follow the steps to enable the camera' +
							'<br/><br/>1. Return to the enrollment form and press the scan button.' +
							'<br/><br/>2. When prompted for permission to use the camera, select "Allow".',
			'overconstrained': 'Camera resolution is not supported by this device.',
			'overconstrainedPortrait': 'Camera resolution is not supported.<br/><br/>' +
                            'Please rotate the device from portrait to landscape and try again.',
			'portrait': 'Please rotate the device from portrait to landscape and try again.',
			'paused': 'Follow the steps to enable the camera' +
						'<br/><br/>1. Return to the enrollment form and press the scan button.' +
                        '<br/><br/>2. When prompted for permission to use the camera, select "Allow".',
			'technicalIssues': 'Sorry, there are technical issues with the camera. Please upload a JPG or PNG image.',
			'submissionError': 'We\'re sorry, but there was an unexpected error while processing the image. ' +
								'Please go to your browser settings to clear the cache and try again.' +
								'Alternatively, you can try again with another image.',
			'invalidDocumentType': 'Invalid image format. Please upload a JPG or PNG image.',
			'invalidRequest': 'We\'re sorry, but there was an unexpected error while processing the image. ' +
								'Please go to your browser settings to clear the cache and try again.',
			'unexpectedError': 'We\'re sorry, but there was an unexpected error while processing the image. ' +
								'Please go to your browser settings to clear the cache and try again.'
		}
	},
	'field': {
		'name': 'Name',
		'locationId': 'Location ID',
		'customerId': 'Customer ID',
		'productName': 'Product Name',
		'ampere': 'Ampere',
		'totalCost': 'Total Cost',
		'totalUsage': 'Total Usage',
		'demandCharge': 'Demand Charge',
		'electric_kana_name': 'Electric Kana Name'
	},
	'documents': {
		'gas': 'Gas',
		'electric': 'Electric',
		'ogas': 'Gas',
		'kfit': 'FIT Notice',
		'reScan': 'Re-scan',
		'fieldCannotEmpty': 'Field cannot be empty',
		'saibuold': 'Saibu Old',
		'saibunew': 'Saibu New',
		'saibu': 'Saibu',
		'kyudensmart': 'Kyuden Smart',
		'kyudenanalog': 'Kyuden Analog',
		'kyuden': 'Kyuden',
		'osaka': 'Osaka Gas',
		'kepco': 'Kepco FIT Notice',
		'other': 'Other',
		'invalidProduct': 'Invalid Product',
		'invalidProductInfo': 'Sorry, this enrollment needs to be completed by phone since this product is not available through this app.',
		'callCenterInfo': 'Saibu Gas Call Center',
		'callCenterNumber': '0570-000-312',
		'callCenterTime': '(Mon-Fri from 9:00-5:45)',
		'error': {
			'badScan': 'No fields could be identified'
		}
	},
	'illustration': {
		'example': 'See an example of a meter reading slip',
		'note': '* The meter reading slip is an image'
	},
	'region': {
		'restart': 'Change region',
		'warning': 'Changing Region will lose any data in the current enrollment',
		'continueButton': 'Continue',
		'mapCallout': 'If you want to check which electric company region you live in',
		'selected': {
			'area': 'Currently <b> {region} </ b> is selected. Would you like to reselect the utility area?',
			'provider': 'Currently <b> {provider} </b> is selected. Would you like to reselect the utility provider?'
		}
	},
	'serviceProvider': {
		'select': {
			'electric': 'Electric power company currently in use',
			'gas': 'Gas company currently in use'
		},
		'auto': 'Auto-selecting provider...',
		'current': 'Your current provider is',
		'other': 'Other provider (please specify)',
		'continue': 'Continue'
	},
	'payment': {
		'profile': {
			'title': 'Credit Card',
			'fieldLabel': {
				'cardNumber': 'Card Number',
				'expDate': 'Expiry Date',
				'month': 'MM',
				'year': 'YY',
				'expDateSample': 'Example:04/28',
				'securityCode': 'Security Code',
				'cardHolder': 'Card Holder'
			},
			'message': {
				'cardFailed': 'Card could not be verified',
				'cardSuccessful': 'Card successfully added to wallet',
				'cardProcessing': 'Verifying card details...'
			},
			'error': {
				'cardNumber': 'Please enter a valid card number',
				'month': 'Please enter a valid month',
				'year': 'Please enter a valid year',
				'date': 'Please enter a valid date',
				'securityCode': 'Please enter a valid security code',
				'cardHolder': 'Please enter a valid name'
			},
			'submit': 'Add card to wallet',
			'resetForm': 'Reset card details'
		}
	},
	'public': {
		'qr': {
			'saibu': 'Saibu Gas gas',
			'kyuden': 'Kyuden Electric Power\'s electricity',
			'instruction1': 'Metered light B or smart family plan',
			'instruction2': 'If you are using any of the above',
			'instruction3': 'Just take a picture of the meter-reading slip. <br/> You can apply easily!',
			'instruction4': 'Access on<br/>smartphone'
		},
		'enrollmentAccess': 'Currently eligible for customers who have a contract for <span style=\'color:red\'>\'Saibu Gas\' and a contract for \'Kyuden Electric Power Meter B or Smart Family Plan.\'</span><br/><br/>For other customers,\nSorry to trouble you, but please apply by phone.',
		'applyStart': 'Apply',
		'gas': 'Step2.<br/>\nPhotograph of Saibu Gas Reading',
		'electric': 'Step3.<br/>Photograph of Kyuden Electric Meter Reading Slip',
		'takePhoto': 'Take Photo',
		'electricInstruction': 'There are two types of meter reading slips.<br/>Please check your meter reading slip and take a photo according to the orange frame.',
		'electricExample': 'See examples of meter reading slips.',
		'or': 'or',
		'reScan': 'Rescan',
		'lostScanPrevious': 'Are you sure you want to re-scan?<br/>The previous scan will be lost.',
		'cancelReScan': 'No, cancel re-scan',
		'confirmReScan': 'Yes, re-scan',
		'returnToForm': 'Return to enrollment form',
		'titleTerms': 'Please check the terms and conditions',
		'subtitleTerms': 'If there is no problem with the contents, check \'I agree\' and proceed to the application.',
		'agreeToTerms': 'I agree',
		'returnToConfirmation': '>Return to Confirmation Screen',
		'applyFinal': 'Apply',
		'applicationCompleted': 'Application Completed！',
		'thankYou': 'Thank you for applying to Saibu Gas',
		'confirmationMail': 'We will send a confirmation by mail once the process is completed.',
		'returnLink': '>Return to Saibu Gas TOPへ'
	},
	'reload': {
		'title': 'Reload site?',
		'body': 'Any changes you made may not be saved.',
		'yes': 'Yes',
		'cancel': 'Cancel'
	},
	'commonMsg': {
		'browserNotSupported': 'Unsupported Browser',
		'switchBrowser': {
			'detailed': 'Unfortunately your browser does not support this application. Please switch to {browser} to continue.',
			'compact': '. Please switch to {browser} to use this application.',
			'callout': 'Supported browsers are Safari（iOS）and Chrome（Android).  We apologize for the inconvenience, but please reopen the link in a supported browser.'
		},
		'nameEmployed': 'Employee Name is required',
		'username': 'Username is required',
		'password': 'Password is required',
		'name': 'Name is required',
		'branchCode': 'Branch Code is required',
		'locationId': 'Location ID is required',
		'customerId': 'Customer ID is required',
		'gasLocationId': '',
		'gasCustomerId': 'Gas Customer ID is required',
		'productName': 'Product Name is required',
		'phoneNumber': 'Phone Number is required',
		'alternatePhone': 'Please enter a valid phone number',
		'email': 'Email address is required',
		'ampere': 'Ampere is required',
		'totalCost': 'Total Cost is required',
		'totalUsage': 'Total Usage is required',
		'demandCharge': 'Demand Charge is required',
		'address': 'Address is required',
		'generationOutput': 'Generation Output is required',
		'facilityId': 'Facility ID is required',
		'expirationDate': 'Notice Expiration Date is required',
		'fieldOutOfBounds': 'Input cannot exceed 255 characters',
		'missingIncompleteField': 'Missing or incorrect entry',
		'youNeedLogin': 'You need to login to view this content',
		'incorrectLoginPassword': 'Incorrect username or password',
		'incorrectBranchCode': 'Incorrect branch code',
		'fieldEditedSuccessfully': 'Field edited successfully',
		'couldNotUpdateField': 'Could not update field',
		'couldNotDeleteDocument': 'Could not delete document',
		'couldNotGetDocumentResults': 'Could not get document results',
		'thereWasErrorCompletingEnrollment': 'There was an error completing this enrollment',
		'sessionTimedOut': 'Session Timed Out',
		'sessionHasExpired': 'Sorry, your session has expired,<br> please restart the enrollment to continue',
		'restartEnrollment': 'Restart enrollment',
		'cantLogout': 'Can\'t logout the current user',
		'couldNotGetEnrollmentDocs': 'Could not get enrollment document',
		'fieldResetSuccessfully': 'Field reset successfully',
		'electricLocationIdPatternError': 'Format should be 22 digits, starting with 09 and ending with 0000-0000',
		'electricAddressPatternError': 'Address input should not exceed 255 characters [Translate]',
		'gasLocationIdPatternError': 'Format should be 10 digits, starting with 700',
		'electricCustomerIdPatternError': 'Format should be 19 digits, starting with 50 and ending with 31',
		'gasCustomerIdPatternError': 'Format should be 10 digits, starting with 900',
		'invalidDate': 'Please enter a valid date',
		'invalidDateAge': 'Your age does not permit you to create an enrollment',
		'patternError': 'Pattern error',
		'errorPageTitle': 'Oops!!',
		'errorPageInfo': 'The page you\'re looking for isn\'t here.',
		'invalidEmail': 'Please enter a valid email address',
		'invalidPhone': 'Please enter a valid phone number',
		'correctFields': 'Please correct the following fields: ',
		'thankyou': 'Thank you',
		'confirmation': 'Confirmation',
		'unexpectedError': 'Unexpected error',
		'duplicateFieldError': 'Values must match',
		'duplicatePhoneError': 'Phone numbers do not match',
		'clickHere': 'Click Here',
		'existingCustomerDefaultError': 'The customer has already subscribed to electricity.'
	}
}
