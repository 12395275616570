const Landing = () => import('@/pages/Landing.vue')
const Intro = () => import('@/pages/Intro.vue')
const Enrollment = () => import('@/pages/Enrollment.vue')
const Camera = () => import('@/pages/Camera.vue')
const Confirmation = () => import('@/pages/Confirmation.vue')
const TermsConditions = () => import('@/pages/TermsConditions.vue')
const ElectronicSignature = () => import('@/pages/ElectronicSignature.vue')
const ThankYou = () => import('@/pages/ThankYou.vue')
const Error = () => import('@/pages/Error.vue')
const NotFound = () => import('@/pages/NotFound.vue')

export default [
	{
		path: '/',
		name: 'landing',
		component: Landing
	},
	{
		path: '/intro/',
		name: 'intro',
		component: Intro
	},
	{
		path: '/enrollment/',
		name: 'enrollment',
		component: Enrollment,
		meta: { keepAlive: true, cacheOnReload: true }
	},
	{
		path: '/camera/',
		name: 'camera',
		component: Camera,
		props: true,
		meta: { cacheOnReload: true }
	},
	{
		path: '/confirmation',
		name: 'confirmation',
		component: Confirmation,
		meta: { keepAlive: true, cacheOnReload: true }
	},
	{
		path: '/terms-conditions',
		name: 'termsConditions',
		component: TermsConditions,
		meta: { keepAlive: true, cacheOnReload: true }
	},
	{
		path: '/electronic-signature',
		name: 'electronicSignature',
		component: ElectronicSignature,
		meta: { keepAlive: true, cacheOnReload: true }
	},
	{
		path: '/thank-you',
		name: 'thankYou',
		component: ThankYou
	},
	{
		path: '/error',
		name: 'error',
		component: Error,
		props: true
	},
	{
		path: '/404/',
		name: 'notFound',
		component: NotFound
	}
]
