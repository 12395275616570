import { Model } from '@vuex-orm/core'
// import ProviderServiceRegion from '@/models/ProviderServiceRegion'
// import Region from '@/models/Region'

export default class ServiceProvider extends Model {
	static entity = 'serviceProvider'

	static fields () {
		return {
			id: this.number(0),
			name: this.string('')
			// regions: this.belongsToMany(Region, ProviderServiceRegion, 'serviceProviderId', 'regionId')
		}
	}
}
