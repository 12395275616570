import axios from 'axios'
import rg4js from 'raygun4js'

const state = {
	brand: {}
}

const getters = {
	getBrand: function () {
		return state.brand
	}
}

const mutations = {
	setBrand: function (state, brand) {
		state.brand = brand
	}
}

const actions = {
	loadBrand: async function ({ commit }) {
		try {
			const response = await axios.get('api/brand')
			const apiBrand = await response.data
			commit('setBrand', apiBrand)
			return apiBrand
		} catch {
			rg4js('send', { error: 'Brand does not exist', tags: ['loading-brand'] })
			return null
		}
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
