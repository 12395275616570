import { Model } from '@vuex-orm/core'
import DocumentType from '@/models/DocumentType'
import DocumentField from '@/models/DocumentField'

export default class ProcessingDocument extends Model {
	static entity = 'processingDocuments'

	static fields () {
		return {
			id: this.number(0),
			status: this.string(''),
			docTypeId: this.number(0),
			enrollmentId: this.number(0),
			regionId: this.number(0),
			serviceProviderId: this.number(null).nullable(),
			serviceProviderName: this.string(''),
			serviceTypeId: this.number(0),
			documentType: this.belongsTo(DocumentType, 'docTypeId', 'id'),
			documentFields: this.hasMany(DocumentField, 'documentId', 'id')
		}
	}
}
