import { Model } from '@vuex-orm/core'
import DocumentFieldCategory from '@/models/DocumentFieldCategory'

export default class DocumentFieldType extends Model {
	static entity = 'documentFieldTypes'

	static fields () {
		return {
			id: this.number(0),
			name: this.string(''),
			validPattern: this.string(null).nullable(),
			mask: this.string(null).nullable(),
			optional: this.boolean(false),
			documentFieldCategoryId: this.number(0),
			documentFieldCategory: this.belongsTo(DocumentFieldCategory, 'documentFieldCategoryId', 'id'),
			dataType: this.string('')
		}
	}
}
