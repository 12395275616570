import DocumentField from '@/models/DocumentField'
import { InstanceOf } from '@vuex-orm/core'

export default class DocumentFieldRepo {
	static endpoint = '/api/document-field'

	static fetchByDocumentId (documentId: number) {
		return DocumentField.api().get(`${this.endpoint}?document-id=${documentId}`)
	}

	static update (documentField: InstanceOf<DocumentField>) {
		return DocumentField.api().put(this.endpoint, [ documentField ])
	}
}
