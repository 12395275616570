import { Model } from '@vuex-orm/core'

export default class Agreement extends Model {
	static entity = 'agreements'

	static fields () {
		return {
			id: this.number(0),
			location: this.string(''),
			type: this.string(''),
			header: this.string(null).nullable(),
			scrollToEnd: this.boolean(true),
			content: this.string(null).nullable()
		}
	}
}
