import { Model } from '@vuex-orm/core'
import ServiceProvider from '@/models/ServiceProvider'

export default class DocumentIllustration extends Model {
	static entity = 'documentIllustrations'

	static fields () {
		return {
			id: this.number(0),
			url: this.string(''),
			serviceTypeId: this.number(0),
			regionId: this.number(0),
			serviceProviderId: this.number(null).nullable(),
			serviceProvider: this.belongsTo(ServiceProvider, 'serviceProviderId', 'id')
		}
	}
}
