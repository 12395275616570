import QueryParameter from '@/models/QueryParameter'
import QueryParameterRepo from '@/repos/QueryParameterRepo'
import store from '../store'

export default class QueryParameterService {
	static cacheQueryParameters () {
		let urlSearchParams = new URLSearchParams(window.location.search)
		store.commit('cms/setQueryParameters', this.extractQueryParameters(urlSearchParams))
	}

	static extractQueryParameters (urlSearchParams: URLSearchParams) {
		return Array.from(urlSearchParams.entries(), ([paramKey, paramValue]) => ({
			key: paramKey,
			value: paramValue
		}))
	}

	static async storeQueryParameters (enrollmentId: number) {
		if (QueryParameter.all().length > 0) return
		let queryParams = store.getters['cms/getQueryParameters']
		if (queryParams.length <= 0) return
		await QueryParameterRepo.create(this.serializeQueryParameters(enrollmentId, queryParams))
	}

	static serializeQueryParameters (enrollmentId: number, queryParams: Array<any>) {
		return queryParams.map(queryParam => ({
			enrollmentId,
			key: queryParam.key,
			value: queryParam.value
		}))
	}

	static async reset () {
		await QueryParameter.deleteAll()
		store.commit('cms/resetUrl')
	}
}
