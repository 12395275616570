import store from '../store'

export default class BrandingService {
	private static instance?: BrandingService
	private config:any

	private constructor () {
		const brand = store.getters['session/getBrand']
		this.config = JSON.parse(brand.config)
	}

	static get () {
		if (!BrandingService.instance) {
			BrandingService.instance = new BrandingService()
		}
		return BrandingService.instance
	}

	getConfig () {
		return this.config
	}

	getUi () {
		return this.config.ui
	}

	getEnrollment () {
		return this.config.enrollment
	}

	getTracker () {
		return this.config.tracker
	}

	getWeb () {
		return this.config.web
	}

	getAgreement () {
		return this.config.agreement
	}
}
