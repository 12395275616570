import { Model } from '@vuex-orm/core'
import ServiceType from '@/models/ServiceType'

export default class DocumentType extends Model {
	static entity = 'documentTypes'

	static fields () {
		return {
			id: this.number(0),
			serviceTypeId: this.number(0),
			regionId: this.number(null).nullable(),
			name: this.string(''),
			maskUrl: this.string(''),
			serviceType: this.belongsTo(ServiceType, 'serviceTypeId', 'id')
		}
	}
}
