import { InstanceOf } from '@vuex-orm/core'
import CardContent from '@/models/CardContent'
import ContentDocumentScan from '@/models/ContentDocumentScan'
import ContentItem from '@/models/ContentItem'
import ContentGroupItem from '@/models/ContentGroupItem'
import DocumentField from '@/models/DocumentField'
import ProcessingDocument from '@/models/ProcessingDocument'
import ServiceType from '@/models/ServiceType'
import DocumentFieldTypeRepo from '@/repos/DocumentFieldTypeRepo'
import DocumentFieldRepo from '@/repos/DocumentFieldRepo'
import EnrollmentRepo from '@/repos/EnrollmentRepo'
import ProcessingDocumentRepo from '@/repos/ProcessingDocumentRepo'
import CardService from '@/services/CardService'
import ContentGroupService from '@/services/ContentGroupService'
import EnrollmentService from '@/services/EnrollmentService'
import FacilityService from '@/services/FacilityService'
import ScanService from 'ocr-services/scan'
import store from '@/store'
import rg4js from 'raygun4js'
import * as Sentry from '@sentry/vue'

export default class DocumentService {
	private processingDocument: InstanceOf<ProcessingDocument>
	private contentDocumentScan: InstanceOf<ContentDocumentScan>
	private enrollmentService:EnrollmentService
	private cardId!: number
	private contentGroupService?:ContentGroupService

	constructor (enrollmentService: EnrollmentService,
		contentDocumentScan: InstanceOf<ContentDocumentScan> = null, contentGroupService?: ContentGroupService) {
		this.enrollmentService = enrollmentService
		this.contentDocumentScan = contentDocumentScan
		this.contentGroupService = contentGroupService
	}

	static validateAllByCardId (cardId: number) {
		return DocumentService.getContentDocumentScansByCardId(cardId)
			.reduce((valid, contentDocumentScan: InstanceOf<ContentDocumentScan>) =>
				(valid && contentDocumentScan && (contentDocumentScan.$valid ||
						(contentDocumentScan.$valid === undefined && contentDocumentScan.optional))), true)
	}

	static validateAllByContentGroup (contentGroupId: number) {
		return DocumentService.getContentDocumentScansByContentGroup(contentGroupId)
			.reduce((valid, contentDocumentScan: InstanceOf<ContentDocumentScan>) =>
				(valid && contentDocumentScan && (contentDocumentScan.$valid ||
						(contentDocumentScan.$valid === undefined && contentDocumentScan.optional))), true)
	}

	static getAllContentDocumentScansByCard (cardId: number) {
		return this.getContentDocumentScansByCardId(cardId).concat(this.getContentDocumentScansByAllGroupsInCard(cardId))
			.filter(contentDocumentScan => contentDocumentScan !== null)
	}

	static getContentDocumentScansByCardId (cardId: number) {
		return CardContent.query().where('cardId', cardId).withAll().get()
			.filter((cardContent: InstanceOf<CardContent>) =>
				cardContent.contentItem && cardContent.contentItem.contentType === 'DOCUMENT_SCAN')
			.map((cardContent: InstanceOf<CardContent>) =>
				ContentDocumentScan.query().whereId(cardContent.contentItem.documentScanId).first())
	}

	static getContentDocumentScansByAllGroupsInCard (cardId: number) {
		const contentGroupIds = CardContent.query().where('cardId', cardId).withAllRecursive().get()
			.filter((cardContent: InstanceOf<CardContent>) =>
				cardContent.contentItem && cardContent.contentItem.contentType === 'CONTENT_GROUP')
			.map((cardContent: InstanceOf<CardContent>) => cardContent.contentItem.contentGroupId)
		let documents: Array<InstanceOf<ContentDocumentScan>> = []
		contentGroupIds.forEach((contentGroupId) => {
			documents = [...documents, ...DocumentService.getContentDocumentScansByContentGroup(contentGroupId)]
		})
		return documents
	}

	static getContentDocumentScansByContentGroup (contentGroupId: number) {
		return ContentGroupItem.query().where('contentGroupId', contentGroupId).withAll().get()
			.filter((contentGroupItem: InstanceOf<ContentGroupItem>) => contentGroupItem.contentItem &&
				contentGroupItem.contentItem.contentType === 'DOCUMENT_SCAN')
			.map((contentGroupItem: InstanceOf<ContentGroupItem>) =>
				ContentDocumentScan.query().whereId(contentGroupItem.contentItem.documentScanId).first())
	}

	static async reset () {
		await DocumentField.deleteAll()
		await ProcessingDocument.deleteAll()
	}

	async createDocument () {
		try {
			const document = this.serializeDocument()
			this.processingDocument = await ProcessingDocumentRepo.post(document)
			return this.processingDocument
		} catch (error) {
			throw new Error('Error creating document: ' + error)
		}
	}

	async scanDocument (imageBlob: any) {
		try {
			await this._rejectExistingDocuments()
			let processingDocument: InstanceOf<ProcessingDocument> = await this.getOrCreateProcessingDocument()
			const enrollmentId = this.enrollmentService.getId()
			const processingDocumentId = processingDocument.id
			if (processingDocumentId) {
				const processingDocument = await ScanService.post(imageBlob, processingDocumentId, enrollmentId)
				await Promise.all([
					ProcessingDocument.update(processingDocument),
					DocumentFieldTypeRepo.fetchByDocumentId(processingDocumentId),
					DocumentFieldRepo.fetchByDocumentId(processingDocumentId)
				])
				this.setProcessingDocument(processingDocument)
				store.commit('cms/setDocumentScanned', null)
				await this.validate()
			} else {
				await rg4js('send', {
					error: `Processing Document Error.`,
					customData: [{
						enrollmentId,
						processingDocumentId,
						imageSize: imageBlob.size,
						imageType: imageBlob.type,
						imageName: imageBlob.name
					}],
					tags: ['document-service']
				})
				throw new Error('Error processing document')
			}
		} catch (error) {
			console.error('Error processing document')
			await this._rejectFailedDocuments()
			await this.deleteProcessingDocument()
			Sentry.captureException('Processing Document Error: ' + error)
			throw error
		}
	}

	async _rejectFailedDocuments () {
		await ProcessingDocument.update({
			where: document =>
				document.enrollmentId === this.enrollmentService.getId() &&
				document.regionId === this.enrollmentService.getRegionId() &&
				document.serviceTypeId === this.contentDocumentScan.serviceTypeId &&
				document.serviceProviderId === this.getServiceProviderId() &&
				document.status === 'NEW',
			data: { status: 'REJECTED' }
		})
	}

	async _rejectExistingDocuments () {
		await FacilityService.reset()
		if (this.processingDocument && this.processingDocument.status !== 'NEW') {
			await EnrollmentRepo.rejectDocuments(this.enrollmentService.getId(), this.contentDocumentScan.serviceTypeId)
		}
		await ProcessingDocument.update({
			where: document =>
				document.enrollmentId === this.enrollmentService.getId() &&
				document.regionId === this.enrollmentService.getRegionId() &&
				document.serviceTypeId === this.contentDocumentScan.serviceTypeId &&
				document.serviceProviderId === this.getServiceProviderId() &&
				document.status === 'COMPLETE',
			data: { status: 'REJECTED' }
		})
	}

	setProcessingDocument (processingDocument: InstanceOf<ProcessingDocument>) {
		this.processingDocument = processingDocument
	}

	getProcessingDocument () {
		const document = this.serializeDocument()
		this.processingDocument = ProcessingDocument.query()
			.where((processingDocument) =>
				processingDocument.enrollmentId === document.enrollmentId &&
				processingDocument.regionId === document.regionId &&
				processingDocument.serviceTypeId === document.serviceTypeId &&
				processingDocument.serviceProviderId === document.serviceProviderId &&
				processingDocument.status !== 'REJECTED')
			.first()
		return this.processingDocument
	}

	getOrCreateProcessingDocument () {
		return this.getProcessingDocument() || this.createDocument()
	}

	async deleteProcessingDocument () {
		if (store.getters['cms/getDeleteDocumentCheckpoint']) return
		store.commit('cms/setDeleteDocumentCheckpoint', true)
		store.commit('cms/setDocumentScanned', null)
		await this._rejectExistingDocuments()
		const processingDocument = await this.getProcessingDocument()
		if (!processingDocument || processingDocument.status !== 'NEW') {
			await this.createDocument()
		}
		await this.validate()
		store.commit('cms/setDeleteDocumentCheckpoint', false)
	}

	private serializeDocument () {
		return {
			serviceTypeId: this.contentDocumentScan.serviceTypeId,
			regionId: this.enrollmentService.getRegionId(),
			serviceProviderId: this.getServiceProviderId(),
			enrollmentId: this.enrollmentService.getId(),
			serviceProviderName: this.getServiceProviderName()
		}
	}

	getDocumentFields () {
		if (this.processingDocument) {
			const processingDocument: InstanceOf<ProcessingDocument> =
				ProcessingDocument.query().whereId(this.processingDocument.id).withAllRecursive().first()
			return (processingDocument && processingDocument.documentFields) ? processingDocument.documentFields : []
		}
		return []
	}

	getServiceType () {
		return ServiceType.find(this.contentDocumentScan.serviceTypeId)
	}

	async updateDocumentFieldValue (documentField: InstanceOf<DocumentField>, value: string) {
		documentField.value = value
		await DocumentField.update(documentField)
	}

	async updateDocumentFieldValid (documentField: InstanceOf<DocumentField>, valid: boolean) {
		const validityChanged = documentField.$valid !== valid
		if (validityChanged) {
			documentField.$valid = valid
			await DocumentField.update(documentField)
			await this.validate()
		}
	}

	async saveDocumentField (documentField: InstanceOf<DocumentField>) {
		await DocumentFieldRepo.update(documentField)
	}

	getValid () {
		if (!this.getProcessingDocument()) return this.contentDocumentScan.optional
		return (this.getDocumentFields().length > 0) && this.getDocumentFields().every(documentField => documentField.$valid)
	}

	async validate () {
		const valid = this.getValid()
		this.contentDocumentScan.$valid = valid
		await ContentDocumentScan.update({ id: this.contentDocumentScan.id, $valid: valid })
		await this.updateParentValidity()
	}

	async updateParentValidity () {
		if (this.contentGroupService && this.contentGroupService.isVisible()) {
			// recalculate validation of the group this field belongs to
			await this.contentGroupService.validate()
		} else {
			// recalculate validation of the card this document belongs to
			await new CardService(this.enrollmentService, this.getCardId()).validate()
		}
	}

	getCardId () {
		if (!this.cardId) {
			const cardContent: InstanceOf<CardContent> = this.getCardContent()
			this.cardId = cardContent ? cardContent.cardId : null
		}
		return this.cardId
	}

	getCardContent () {
		let contentItem: InstanceOf<ContentItem> = this.getContentItem()
		let cardContent: InstanceOf<CardContent> = contentItem
			? CardContent.query().where('contentItemId', contentItem.id).first()
			: null
		if (!cardContent) {
			// Check for Card Content if document is part of a Content Group
			const contentGroupItem: InstanceOf<ContentGroupItem> = ContentGroupItem.query()
				.where('contentItemId', contentItem.id).first()
			contentItem = ContentItem.query().where('contentGroupId', contentGroupItem.contentGroupId).first()
			cardContent = CardContent.query()
				.where(cardContent => cardContent.contentItemId === contentItem.id).first()
		}
		return cardContent
	}

	getContentItem () {
		return ContentItem.query().where('documentScanId', this.contentDocumentScan.id).first()
	}

	getContentDocumentScan () {
		return this.contentDocumentScan
	}

	getServiceProviderId () {
		return this.contentDocumentScan.$serviceProviderId
	}

	getServiceProviderName () {
		return this.contentDocumentScan.$serviceProviderName
	}

	async setServiceProviderId (serviceProviderId) {
		this.contentDocumentScan.$serviceProviderId = serviceProviderId
		await ContentDocumentScan.update(this.contentDocumentScan)
	}

	async setServiceProviderName (serviceProviderName) {
		this.contentDocumentScan.$serviceProviderName = serviceProviderName
		await ContentDocumentScan.update(this.contentDocumentScan)
	}
}
