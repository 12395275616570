import Facility from '@/models/Facility'
import { InstanceOf } from '@vuex-orm/core'

export default class FacilityRepo {
	static endpoint = '/api/facility'

	static async create (enrollmentId: number, locationId: number, regionId: number) {
		const result = await Facility.api().post(this.endpoint, { enrollmentId, locationId, regionId })
		const createdFacility: InstanceOf<Facility> = result && result.entities ? result.entities.facilities[0] : null
		return createdFacility
	}
}
