import cmsDefaults from '@/store/cms/defaults'

const flow = {
	state: {
		flow: { ...cmsDefaults.flow }
	},
	getters: {
		getIntroCheckpoint: function (state) {
			return state.flow.introCheckpoint
		},
		getConfirmationCheckpoint: function (state) {
			return state.flow.confirmationCheckpoint
		},
		getPreconditionsCheckpoint: function (state) {
			return state.flow.preconditionsCheckpoint
		},
		getReloadCheckpoint: function (state) {
			return state.flow.reloadCheckpoint
		},
		getResetCheckpoint: function (state) {
			return state.flow.resetCheckpoint
		},
		getDocumentsCreated: function (state) {
			return state.flow.documentsCreated
		},
		getDocumentScanned: function (state) {
			return state.flow.documentScanned
		},
		getDeleteDocumentCheckpoint: function (state) {
			return state.flow.deleteDocumentCheckpoint
		}
	},
	mutations: {
		resetFlow: function (state) {
			state.flow = { ...cmsDefaults.flow }
		},
		setIntroCheckpoint: function (state, status) {
			state.flow.introCheckpoint = status
		},
		setConfirmationCheckpoint: function (state, status) {
			state.flow.confirmationCheckpoint = status
		},
		setPreconditionsCheckpoint: function (state, status) {
			state.flow.preconditionsCheckpoint = status
		},
		setReloadCheckpoint: function (state, status) {
			state.flow.reloadCheckpoint = status
		},
		setResetCheckpoint: function (state, status) {
			state.flow.resetCheckpoint = status
		},
		setDocumentsCreated: function (state, status) {
			state.flow.documentsCreated = status
		},
		setDocumentScanned: function (state, status) {
			state.flow.documentScanned = status
		},
		setDeleteDocumentCheckpoint: function (state, status) {
			state.flow.deleteDocumentCheckpoint = status
		}
	}
}

export const flowState = flow.state
export const flowGetters = flow.getters
export const flowMutations = flow.mutations
export const flowActions = flow.actions
