import CustomerField from '@/models/CustomerField'
import { InstanceOf } from '@vuex-orm/core'

export default class CustomerFieldRepo {
	static endpoint = '/api/customer-field'

	static fetchByEnrollmentId (enrollmentId: number) {
		return CustomerField.api().get(`${this.endpoint}?enrollment-id=${enrollmentId}`)
	}

	static update (customerField: InstanceOf<CustomerField>) {
		return CustomerField.api().put(this.endpoint, customerField)
	}
}
