import Card from '@/models/Card'
import CardContent from '@/models/CardContent'
import ContentGroupService from '@/services/ContentGroupService'
import CustomerFieldService from '@/services/CustomerFieldService'
import DocumentService from '@/services/DocumentService'
import EnrollmentService from '@/services/EnrollmentService'
import { InstanceOf } from '@vuex-orm/core'

export default class CardService {
	private card: InstanceOf<Card>;
	private cardId: number
	private cardContents?: Array<CardContent>
	private enrollmentService:EnrollmentService

	constructor (enrollmentService: EnrollmentService, cardId: number) {
		this.cardId = cardId
		this.enrollmentService = enrollmentService
	}

	static validateAll () {
		return (Card.all().length > 0) && Card.all().every((card: InstanceOf<Card>) => card.$valid)
	}

	getCard () {
		if (!this.card) {
			this.card = Card.query().whereId(this.cardId).withAll().first()
		}
		return this.card
	}

	async validate () {
		const cardId = this.getCard().id
		const customerFieldValidity = CustomerFieldService.validateAllByCardId(cardId)
		const documentValidity = DocumentService.validateAllByCardId(cardId)
		const contentGroupValidity = ContentGroupService.validateAllByCardId(cardId)
		const valid = customerFieldValidity && documentValidity && contentGroupValidity
		await this.updateValidity(valid)
		await this.updateParentValidity()
		return valid
	}

	async updateParentValidity () {
		await this.enrollmentService.validate()
	}

	async updateValidity (valid: boolean) {
		this.getCard().$valid = valid
		await Card.update({ id: this.getCard().id, $valid: valid })
	}

	getCardContents () {
		if (!this.cardContents) {
			this.cardContents = CardContent.query().where('cardId', this.getCard().id).withAll().orderBy('order').get()
		}
		return this.cardContents
	}

	getValid () {
		return this.card.$valid
	}
}
