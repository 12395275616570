import cmsDefaults from '@/store/cms/defaults'

const device = {
	state: {
		device: { ...cmsDefaults.device }
	},
	getters: {
		getDevice: function (state) {
			return state.device
		}
	},
	mutations: {
		setIsDesktop: function (state, isDesktop) {
			state.device.isDesktop = isDesktop
		},
		setIsIOSDevice: function (state, isiOSDevice) {
			state.device.isIOSDevice = isiOSDevice
		},
		setIsIE: function (state, isIE) {
			state.device.isIE = isIE
		},
		setNativeBrowser: function (state, nativeBrowser) {
			state.device.nativeBrowser = nativeBrowser
		},
		setIsUnsupportedBrowser: function (state, isUnsupportedBrowser) {
			state.device.isUnsupportedBrowser = isUnsupportedBrowser
		},
		setIsMediaSupported: function (state, isMediaSupported) {
			state.device.isMediaSupported = isMediaSupported
		},
		setVideoInputsExist: function (state, videoInputsExist) {
			state.device.videoInputsExist = videoInputsExist
		},
		setIsCompatibleDevice: function (state, isCompatibleDevice) {
			state.device.isCompatibleDevice = isCompatibleDevice
		},
		setIsIOSOldVersion: function (state, isIOSOldVersion) {
			state.device.isIOSOldVersion = isIOSOldVersion
		}
	},
	actions: {
		loadDevice: async function ({ dispatch }) {
			await dispatch('loadIsIOSDevice')
			await dispatch('loadVideoInputsExist')

			await dispatch('loadIsDesktop')
			await dispatch('loadNativeBrowser')
			await dispatch('loadIsIE')
			await dispatch('loadIsUnsupportedBrowser')
			await dispatch('loadIsMediaSupported')
			await dispatch('loadIsCompatibleDevice')
			await dispatch('loadIsIOSOldVersion')
		},
		loadIsIOSDevice: function ({ commit }) {
			const isTouchScreen = navigator.maxTouchPoints > 1
			const isIOS = !!(/iPhone|iPad/i.test(navigator.platform))
			// iPads over version 13 need to be differentiated from other Apple devices
			const isAppleDevice = !!(/MacIntel/i.test(navigator.platform))
			const isIPad = isAppleDevice && isTouchScreen
			commit('setIsIOSDevice', (isIOS || isIPad))
		},
		loadIsIE: function ({ commit }) {
			const isIE = navigator.userAgent.indexOf('MSIE') >= 0 || navigator.userAgent.indexOf('Trident') >= 0
			commit('setIsIE', isIE)
		},
		loadIsDesktop: function ({ commit, state }) {
			const isAndroid = !!(/Android/i.test(navigator.userAgent))
			const isMobile = !!(/Mobile|Smartphone|Tablet|iPhone|iPad|Phone/i.test(navigator.userAgent))
			commit('setIsDesktop', !state.device.isIOSDevice && !isAndroid && !isMobile)
		},
		loadNativeBrowser: function ({ commit, state }) {
			commit('setNativeBrowser', state.device.isIOSDevice ? 'Safari' : 'Chrome')
		},
		loadIsUnsupportedBrowser: function ({ commit, state }) {
			const isUnsupportediOS = !!(/CriOS|FxiOS|Firefox|Line|UCBrowser/i.test(navigator.userAgent))
			const isUnsupportedAndroid = !!(/.*Android.*Line|.*Android.*UCBrowser|.*Android.*Mobile.*Firefox/i.test(navigator.userAgent))
			commit('setIsUnsupportedBrowser', isUnsupportedAndroid || (isUnsupportediOS && state.device.isIOSDevice))
		},
		loadIsMediaSupported: function ({ commit }) {
			commit('setIsMediaSupported', !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia))
		},
		loadVideoInputsExist: async function ({ commit }) {
			let devices = []
			if (navigator.mediaDevices) devices = await navigator.mediaDevices.enumerateDevices()
			const videoInputsExist = devices.filter(device => device.kind.indexOf('video') >= 0).length > 0
			commit('setVideoInputsExist', videoInputsExist)
		},
		loadIsCompatibleDevice: function ({ commit, state }) {
			const isCompatible = state.device.isMediaSupported &&
				!state.device.isUnsupportedBrowser &&
				(state.device.videoInputsExist || state.device.isIOSDevice)
			commit('setIsCompatibleDevice', isCompatible)
		},
		loadIsIOSOldVersion: function ({ commit }) {
			commit('setIsIOSOldVersion', !!navigator.userAgent.match(/iPhone OS 12_5*/))
		}
	}
}

export const deviceState = device.state
export const deviceGetters = device.getters
export const deviceMutations = device.mutations
export const deviceActions = device.actions
