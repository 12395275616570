<script>
import Vue from 'vue'
import BrandingService from '@/services/BrandingService'
import VueAnalytics from 'vue-analytics'
import VueGtm from 'vue-gtm'
import rg4js from 'raygun4js'
import i18n from '@/i18n'
import merge from 'lodash/merge'

export default {
	name: 'App',
	data: () => ({
		loading: true
	}),
	computed: {
		brand: function () {
			return this.$store.getters['session/getBrand']
		},
		isProduction: function () {
			return process.env.NODE_ENV === 'production'
		}
	},
	created: async function () {
		await this._loadDeviceInfo()
		await this._loadBrand()
		await this._loadLocales()
		await this._loadStyles()
		this._loadBrowserConfig()
		this._loadRaygun()
		this.loading = false
	},
	updated: async function () {
		await this._loadGoogleAnalytics()
	},
	methods: {
		_loadDeviceInfo: async function () {
			await this.$store.dispatch('cms/loadDevice')
		},
		_loadBrand: async function () {
			if (this.brand.id) return
			const currentBrand = await this.$store.dispatch('session/loadBrand')
			if (!currentBrand) await this.$router.push({ name: 'notFound' })
		},
		_loadLocales: async function () {
			const clientLanguage = this.brand.language
			await this._mergeLocales(clientLanguage)
			i18n.locale = clientLanguage
		},
		_loadStyles: async function () {
			const styleJSON = JSON.parse(this.brand.theme)
			const styleString = Object.entries(styleJSON).map(([key, value]) => `${key}:${value}`).join(';')
			const styleTheme = `[theme = '${this.brand.code}'] {${styleString}}`
			document.head.insertAdjacentHTML('beforeend', `<style>${styleTheme}</style>`)
		},
		_loadBrowserConfig: function () {
			const brandConfig = BrandingService.get().getConfig()
			this._setBrowserTitle(brandConfig)
			this._cleanFavicon()
			this._setBrowserFavicon(brandConfig)
			this._cleanAppleIcon()
			this._setAppleIcon(brandConfig)
		},
		_setBrowserTitle: function (brandConfig) {
			document.title = brandConfig.web.title
		},
		_cleanFavicon: function () {
			const $favicons = document.querySelectorAll('link[rel="icon"]')
			for (let $favicon of $favicons) {
				$favicon.remove()
			}
		},
		_cleanAppleIcon: function () {
			const $appleIcons = document.querySelectorAll('link[rel="apple-touch-icon"]')
			for (let $appleIcon of $appleIcons) {
				$appleIcon.remove()
			}
		},
		_setBrowserFavicon: function (brandConfig) {
			const $favicon = document.createElement('link')
			$favicon.rel = 'icon'
			$favicon.href = `/ocr/img/icons/${this.brand.code}/` + brandConfig.web.favicon
			document.head.appendChild($favicon)
		},
		_setAppleIcon: function (brandConfig) {
			const $appleIcon = document.createElement('link')
			$appleIcon.rel = 'apple-touch-icon'
			$appleIcon.href = `/ocr/img/icons/${this.brand.code}/` + brandConfig.web.appleIcon
			document.head.appendChild($appleIcon)
		},
		_loadRaygun: function () {
			if (!process.env.VUE_APP_RAYGUN_API_KEY) return
			rg4js('apiKey', process.env.VUE_APP_RAYGUN_API_KEY)
			rg4js('enableCrashReporting', true)
			rg4js('enablePulse', true)
			rg4js('options', {
				debugMode: !this.isProduction,
				allowInsecureSubmissions: true,
				disableErrorTracking: false,
				disablePulse: false
			})
			if (this.isProduction) rg4js('disableAutoBreadcrumbsConsole')
		},
		_loadGoogleAnalytics: async function () {
			const tracker = BrandingService.get().getTracker()
			const isBrandProd = (window.location.host.indexOf(tracker.serverName) >= 0)
			const router = this.$router
			if (tracker.gaId) {
				Vue.use(VueAnalytics, {
					id: [tracker.gaId],
					router,
					ignoreRoutes: tracker.ignoreRoutes,
					debug: {
						enabled: !this.isProduction,
						sendHitTask: isBrandProd
					}
				})
			}
			if (tracker.gtmId) {
				Vue.use(VueGtm, {
					id: [tracker.gtmId],
					debug: !this.isProduction,
					enabled: isBrandProd,
					loadScript: true
				})
			}
		},
		_mergeLocales: async function (language) {
			const localeDefault = await import(`@/locales/${language}.js`)
			const localeCMS = await JSON.parse(this.brand.locale)
			let cmsLocaleMerged
			try {
				cmsLocaleMerged = merge(localeDefault.default, localeCMS)
			} catch (e) {
				cmsLocaleMerged = localeCMS
			}
			i18n.setLocaleMessage(language, cmsLocaleMerged)
		}
	}
}
</script>

<template>
	<base-loading class="app__loading" :loading="loading" modifiers="large-spinner light-overlay">
		<div v-if="!loading" :theme="brand.code">
			<keep-alive>
				<router-view v-if="$route.meta.keepAlive" />
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive" />
		</div>
	</base-loading>
</template>

<style lang="scss">
	@import "~lowfoot-components/themes/reset";
	@import "~lowfoot-components/themes/defaultVariables";
	@import "~lowfoot-components/themes/default";
	@import "assets/themes/ocr";
	@import "node_modules/noty/src/noty";
	@import "node_modules/noty/src/themes/mint";

	html, body {
		font-family: 'Noto Sans JP', 'Arial', sans-serif;
	}
	a {
		color: black;
		text-decoration: none; /* no underline */
	}
	.app {
		&__loading {
			min-height: 100vh;
		}
	}

	.noty_buttons {
		display: flex;
		justify-content: space-around;
	}

</style>
