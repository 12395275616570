import { InstanceOf } from '@vuex-orm/core'
import ServiceType from '@/models/ServiceType'

export default class ServiceTypeService {
	serviceType: InstanceOf<ServiceType>

	constructor (serviceType: InstanceOf<ServiceType>) {
		this.serviceType = serviceType
	}

	static findByCode (serviceTypeCode) {
		return ServiceType.query().where('code', serviceTypeCode).first()
	}
	static findById (serviceTypeId: number) {
		return ServiceType.query().whereId(serviceTypeId).first()
	}
}
