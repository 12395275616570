import { Model } from '@vuex-orm/core'

export default class CustomerFieldCategory extends Model {
	static entity = 'customerFieldCategories'

	static fields () {
		return {
			id: this.number(0),
			code: this.string(''),
			dataType: this.string('')
		}
	}
}
