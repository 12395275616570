import { Model } from '@vuex-orm/core'
import ServiceProvider from '@/models/ServiceProvider'
import ProviderServiceRegion from '@/models/ProviderServiceRegion'

export default class Region extends Model {
	static entity = 'regions'

	static fields () {
		return {
			id: this.number(0),
			code: this.string(''),
			tdspCode: this.string(''),
			serviceProviders: this.belongsToMany(ServiceProvider, ProviderServiceRegion, 'regionId', 'serviceProviderId')
		}
	}
}
