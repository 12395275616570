import ContentGroupService from '@/services/ContentGroupService'
import CardService from '@/services/CardService'
import { InstanceOf } from '@vuex-orm/core'
import CardContent from '@/models/CardContent'
import ContentItem from '@/models/ContentItem'
import ContentGroupItem from '@/models/ContentGroupItem'
import CustomerField from '@/models/CustomerField'
import CustomerFieldType from '@/models/CustomerFieldType'
import CustomerFieldRepo from '@/repos/CustomerFieldRepo'
import EnrollmentService from '@/services/EnrollmentService'
import ContentGroup from '@/models/ContentGroup'
import CustomerFieldOption from '@/models/CustomerFieldOption'
import CustomerFieldCategory from '@/models/CustomerFieldCategory'

export default class CustomerFieldService {
	private contentItem: InstanceOf<ContentItem>
	private customerFieldType:InstanceOf<CustomerFieldType>
	private enrollmentService:EnrollmentService
	private contentGroupService?:ContentGroupService
	private cardService?: CardService
	private customerFieldTypeId: number
	private toggledGroups?: Array<ContentGroup>
	private createPromise?: Promise<void>
	private customerFieldCategory: InstanceOf<CustomerFieldCategory>

	constructor (enrollmentService: EnrollmentService, customerFieldTypeId: number, contentGroupService?: ContentGroupService) {
		this.customerFieldTypeId = customerFieldTypeId
		this.enrollmentService = enrollmentService
		this.contentGroupService = contentGroupService
		this.contentItem = ContentItem.query().where('customerFieldTypeId', this.customerFieldTypeId).first()
		this.customerFieldCategory = CustomerFieldCategory.find(this.getCustomerFieldType().customerFieldCategoryId)
	}

	static async reset () {
		await CustomerField.deleteAll()
	}

	static getByCustomerFieldTypeId (enrollmentService: EnrollmentService, customerFieldTypeId: number) {
		return new CustomerFieldService(enrollmentService, customerFieldTypeId)
	}

	static getCustomerFieldsByCard (cardId: number) {
		return CardContent.query().where('cardId', cardId).orderBy('order').withAll().get()
			.filter((cardContent: InstanceOf<CardContent>) => cardContent.contentItem && cardContent.contentItem.contentType === 'CUSTOMER_FIELD')
			.map((cardContent: InstanceOf<CardContent>) =>
				CustomerFieldType.query().whereId(cardContent.contentItem.customerFieldTypeId).withAll().first())
	}

	static getCustomerFieldsByContentGroup (contentGroupId: number) {
		return ContentGroupItem.query().where('contentGroupId', contentGroupId)
			.orderBy('order').withAll().get()
			.filter((contentGroupItem: InstanceOf<ContentGroupItem>) => contentGroupItem.contentItem &&
				contentGroupItem.contentItem.contentType === 'CUSTOMER_FIELD')
			.map((contentGroupItem: InstanceOf<ContentGroupItem>) =>
				CustomerFieldType.query().whereId(contentGroupItem.contentItem.customerFieldTypeId).withAll().first())
	}

	static validateAllByCardId (cardId: number) {
		return CustomerFieldService.getCustomerFieldsByCard(cardId)
			.reduce((valid, customerFieldType:InstanceOf<CustomerFieldType>) =>
				(valid && customerFieldType && customerFieldType.$valid), true)
	}

	static validateAllByContentGroup (contentGroupId: number) {
		const customerFieldTypes = this.getCustomerFieldsByContentGroup(contentGroupId)
		return customerFieldTypes.reduce((valid,
			customerFieldType:InstanceOf<CustomerFieldType>) => (valid && customerFieldType && customerFieldType.$valid), true)
	}

	async getOrCreateCustomerField () {
		await this.getCreatePromise()
		return this.getCustomerField()
	}

	private getCreatePromise () {
		if (!this.createPromise) {
			const serviceInstance = this
			this.createPromise = new Promise((resolve) =>
				resolve(serviceInstance.getCustomerField())
			).then(async function (customerField: InstanceOf<CustomerField>) {
				// return existing Customer Field in the store or create a new placeholder
				if (!customerField) {
					customerField = await CustomerField.new()
					customerField.$isNew = true
					customerField.customerFieldTypeId = serviceInstance.getCustomerFieldType().id
					customerField.enrollmentId = serviceInstance.enrollmentService.getId()
					await CustomerField.update(customerField)
				}
			})
		}
		return this.createPromise
	}

	getCustomerField () {
		return CustomerField.query().where((customerField) =>
			customerField.enrollmentId === this.enrollmentService.getId() &&
			customerField.customerFieldTypeId === this.getCustomerFieldType().id).first()
	}

	getCustomerFieldType () {
		if (!this.customerFieldType) {
			this.customerFieldType = CustomerFieldType.find(this.customerFieldTypeId)
		}
		return this.customerFieldType
	}

	getContentItem () {
		return this.contentItem
	}

	getCategory () {
		return this.customerFieldCategory
	}

	getCardContent () {
		let contentItem: InstanceOf<ContentItem> = this.getContentItem()
		let cardContent: InstanceOf<CardContent> = CardContent.query()
			.where(cardContent => cardContent.contentItemId === contentItem.id).first()
		if (!cardContent) {
			// Check for Card Content if field is part of a Content Group
			const contentGroupItem: InstanceOf<ContentGroupItem> = ContentGroupItem.query()
				.where('contentItemId', contentItem.id).first()
			contentItem = ContentItem.query().where('contentGroupId', contentGroupItem.contentGroupId).first()
			cardContent = CardContent.query()
				.where(cardContent => cardContent.contentItemId === contentItem.id).first()
		}
		return cardContent
	}

	getCardId () {
		const cardContent: InstanceOf<CardContent> = this.getCardContent()
		return cardContent ? cardContent.cardId : null
	}

	getLabel () {
		const contentItem: InstanceOf<ContentItem> = this.getContentItem()
		return contentItem ? contentItem.label : null
	}

	getOptions () {
		return CustomerFieldOption.query().where('customerFieldTypeId', this.customerFieldTypeId).get()
	}

	getDisplayedValue () {
		const options = this.getOptions()
		let foundOption
		if (options && options.length > 0) {
			foundOption = options.find((option:InstanceOf<CustomerFieldOption>) => option.value === this.getValue())
		}
		return foundOption ? foundOption.label : null
	}

	getValid () {
		return this.getCustomerFieldType().$valid
	}

	getValue () {
		const customerField: InstanceOf<CustomerField> = this.getCustomerField()
		return customerField ? customerField.value : null
	}

	async setValid (valid: boolean) {
		const validityChanged = this.getCustomerFieldType().$valid !== valid
		if (validityChanged) {
			this.getCustomerFieldType().$valid = valid
			await CustomerFieldType.update({ id: this.getCustomerFieldType().id, $valid: valid })
			await this.updateParentValidity()
		}
	}

	async updateParentValidity () {
		if (this.contentGroupService && this.contentGroupService.isVisible()) {
			// recalculate validation of the group this field belongs to
			await this.contentGroupService.validate()
		} else {
			// update any groups triggered by this field
			await Promise.all(this.findToggledGroups().map((contentGroup: InstanceOf<ContentGroup>) => {
				const contentGroupService = new ContentGroupService(this.enrollmentService, contentGroup.id)
				return contentGroupService.validate()
			}))
			// recalculate validation of the card this field belongs to
			await this.getCardService().validate()
		}
	}

	getCardService () {
		if (!this.cardService) {
			this.cardService = new CardService(this.enrollmentService, this.getCardId())
		}
		return this.cardService
	}

	findToggledGroups () {
		if (!this.toggledGroups) {
			const contentItem: InstanceOf<ContentItem> = this.getContentItem()
			this.toggledGroups = contentItem ? ContentGroup.query().where('toggleContentItemId', contentItem.id).get() : []
		}
		return this.toggledGroups
	}

	async setValue (value: string) {
		const customerField: InstanceOf<CustomerField> = await this.getOrCreateCustomerField()
		const existsInDB = customerField.id > 0
		customerField.$isDirty = !existsInDB || customerField.value !== value
		customerField.value = value
		await CustomerField.update(customerField)
		// update parent validity based on the new value of this field
		await this.updateParentValidity()
	}

	isDirty () {
		const customerField: InstanceOf<CustomerField> = this.getCustomerField()
		return customerField ? customerField.$isDirty : false
	}

	async saveCustomerField () {
		// remove placeholder CustomerField, if it exists
		let customerField: InstanceOf<CustomerField> = this.getCustomerField()
		const value = customerField.value
		if (customerField.$isNew) await customerField.$delete()
		this.enrollmentService = await EnrollmentService.fetchOrCreate()
		const enrollmentId = this.enrollmentService.getId()

		customerField = this.getCustomerField()
		customerField.enrollmentId = enrollmentId
		customerField.value = value
		await CustomerField.update(customerField)
		CustomerFieldRepo.update(customerField)

		// reset dirty flag now that it's saved to db
		customerField.$isDirty = false
		await CustomerField.update(customerField)
	}

	isHidden () {
		const categoryCode = this.getCategory().code
		if (categoryCode === 'incumbent_plan' && this.enrollmentService.hasElectricGenericProvider()) {
			return true
		}
		return this.contentGroupService && !this.contentGroupService.isVisible()
	}

	isToggleField () {
		return this.findToggledGroups()?.length > 0
	}
}
